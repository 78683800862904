import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiPathsService } from "../../api-paths.service";
import { DatePipe } from "@angular/common";
import { debounceTime } from "rxjs/operators";
import { Subject } from "rxjs";
import * as htmlToImage from 'html-to-image';
import { DynamicCrudService } from '../_services/dynamic-crud.service';

@Component({
  selector: 'app-dynamic-dashboard',
  templateUrl: './dynamic-dashboard.component.html',
  styleUrls: ['./dynamic-dashboard.component.scss']
})
export class DynamicDashboardComponent implements OnInit {
  environment = environment;
  filtri = { inizio: '2023-01-01', fine: '2024-12-31' };
  DashBoardComponent: { titolo: string, valore: any, tipo: string, class: string, clickLink: string, VerticalBarData: { value: any, name: string }[], LineChartData: any, linechartOptions: any, id: string, larghezza: string }[] = [];
  lineChartLabels: any[] | undefined;
  constructor(private apiPath: ApiPathsService, private router: Router, private route: ActivatedRoute, private datepipe: DatePipe, private crudService: DynamicCrudService) { }
  ngOnInit(): void {
    const today = new Date();
    const inizioMese = new Date(today.getFullYear(), today.getMonth(), 1);
    const fineMese = new Date();
    this.filtri.inizio = this.datepipe.transform(inizioMese, 'yyyy-MM-dd');
    this.filtri.fine = this.datepipe.transform(fineMese, 'yyyy-MM-dd');
    this.setupSearchDebouncer();
    this.onSearchInputChange();
  }
  public debouncedInputValue = null;
  private searchDecouncer$: Subject<any> = new Subject();
  CampiFiltro() {
    var output = [];
    if (Array.isArray(environment['DashboardAddon']))
      environment['DashboardAddon']?.forEach((dashboardSector: { url: string, campi: any[], filters: any[], initObj?: any[] }) => {
        output = dashboardSector.filters;

      })
    else if (environment['DashboardAddon'])
      output = environment['DashboardAddon']['filters'];
    //console.log("Filtri dashboard",output)
    return output;

  }
  public onSearchInputChange(): void {
    this.searchDecouncer$.next(this.filtri);
  }

  private setupSearchDebouncer(): void {
    this.searchDecouncer$.pipe(
      debounceTime(500),
    ).subscribe((term: any) => {
      this.debouncedInputValue = term;
      console.log('debouncedInputValue', term)
      this.Refresh(term);
    });
  }
  OnFilter(value: { OnChange: string, Event: any }) {
    this.onSearchInputChange();
  }
  Refresh(filter?) {
    if (Array.isArray(environment['DashboardAddon'])) {
      environment['DashboardAddon']?.forEach((dashboardSector: { url: string, campi: any[], initObj?: any[] }) => {
        var filtro = filter ?? this.filtri
        this.crudService.InitObjects(filtro, dashboardSector.initObj);
        this.apiPath.ClassicPost(dashboardSector.url, filtro).subscribe(data => {
          this.DashBoardComponent = [];
          dashboardSector.campi?.forEach(campo => {
            this.DashBoardComponent.push({
              titolo: campo.nome,
              valore: data[campo?.oggetto],
              VerticalBarData: this.VerticalBarData(data[campo?.oggetto]),
              LineChartData: campo.tipo == 'lineChart' ? this.LineChartData(data[campo?.oggetto]) : [],
              tipo: campo?.tipo ?? 'text',
              class: campo?.class,
              clickLink: campo.clickLink,
              linechartOptions: campo.linechartOptions,
              id: campo.id ?? campo.oggetto,
              larghezza: campo.larghezza
            })
          })
        })
      })
      console.log("OK")
    }
  }

  Click(campi: { titolo: string; valore: number | string; tipo: string; class: string; clickLink: string }) {
    if (!campi.clickLink) return;
    var url = `${campi.clickLink}${campi.clickLink.includes('?') ? '' : '?fromdashboard=true'}`
    Object.getOwnPropertyNames(this.filtri)?.forEach(prop => {
      if (this.filtri[prop] && !prop.includes('Object'))
        url += `&${prop}=${this.filtri[prop]}`
    })
    window.location.href = url;
    // this.router.navigate([this.route.snapshot.queryParams[campi.clickLink] || campi.clickLink,]);
  }

  VerticalBarLabels(valore: any) {
    return valore.map(x => x.label);
  }
  VerticalBarData(valore: any) {
    if (!Array.isArray(valore)) return;
    var verticalBarData = valore?.map(x => {
      return { value: x.value ?? 0, name: x.label ?? 'N/A' }
    }) ?? [];
    console.log("Valore", valore);
    console.log("VerticalBarData", verticalBarData);
    return verticalBarData;
  }
  LineChartData(valore: any) {

    if (!Array.isArray(valore)) return;
    var verticalBarData = [];
    var labels: string[];
    if (!(valore?.length > 0))
      return { dati: [{ data: [0], label: 'N/A' }], labels: ['N/A'] };
    if (valore?.length > 0 && valore.every(x => Array.isArray(x.value))) {
      verticalBarData = valore?.map(x => {
        return { data: x.value?.map(y => y.value ?? 0), label: x.label ?? 'N/A' }
      })
      labels = valore[0]?.value?.map(x => x.label ?? 'N/A')
      var somma = { data: [], label: 'Tutti' };
      labels.forEach(date => {
        const total = valore.reduce((sum, current) => {
          const entry = current.value.find(item => item.label === date);
          return sum + (entry ? entry.value : 0);
        }, 0);
        somma.data.push(total);
      });
      verticalBarData.push(somma);

    }
    else
      verticalBarData = [{ data: valore?.map(x => x.value ?? 0) ?? [0], label: '' }]

    console.log("Valore", valore);
    console.log("LineChartData", verticalBarData);
    return { dati: verticalBarData, labels: labels ?? valore?.map(x => x.label ?? 'N/A') ?? ['N/A'] };
  }
  lineChartOptions: any = {
    responsive: true,
    lineTension: 0,

  };
  @Input() lineChartColors: Array<any> = [
    {
      // grey
      backgroundColor: 'rgba(54,190,166,.1)',
      borderColor: '#36bea6',
      pointBackgroundColor: '#36bea6',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#36bea6'
    },
    {
      // dark grey
      backgroundColor: 'rgb(41,98,255,.1)',
      borderColor: '#2962FF',
      pointBackgroundColor: '#2962FF',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#2962FF'
    }
  ];
  @Input() lineChartLegend = true;
  @Input() lineChartType = 'line';

  generateImage(id) {
    var node: any = document.getElementById(id);
    htmlToImage.toJpeg(node, { quality: 0.95 })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'my-image-name.jpeg';
        link.href = dataUrl;
        link.click();
      });
    // htmlToImage.toPng(node)
    //   .then(function (dataUrl) {
    //     var img = new Image();
    //     img.src = dataUrl;
    //     document.body.appendChild(img);
    //   })
    //   .catch(function (error) {
    //     console.error('oops, something went wrong!', error);
    //   });
  }

}

