<div class="page row">
    <!-- Filtro sidebar -->
    <div class="col-lg-3" *ngIf="FilterViewType == 'sidebar' && openFilter" style="transition: 0.2s ease-in; background-color: white;">
        <div class="card">
        <div class="card-body">
            <h5 class="card-title">Filtri</h5>
            <app-field-viewer [(Campi)]="CrudSettings.Filters" [(Oggetto)]="filtro"(OnChange)="Refresh()"></app-field-viewer>
        </div>
        </div>
    </div>
     <!-- Container -->
    <div [ngClass]="!FilterViewType || FilterViewType == 'normal' || !openFilter ? 'col-12': 'col-lg-9'">
      <div class="d-flex align-items-center justify-content-between">
        <!-- Titolo + Tasto Filtro -->
        <div class="d-md-flex align-items-center bg-white p-2">
            <div class="mt-3 mt-md-0">
              <div class="d-flex align-items-center ml-3 justify-content-start">
                <button type="button"  *ngIf="FilterViewType == 'sidebar'" (click)="openFilter = !openFilter" class="btn p-2">
                  <i class="fas fa-filter  mr-1 " style="font-size: 18px" [ngClass]="openFilter ? 'text-info': 'text-dark'"></i>
                </button>
                <span class="ml-1 text-dark" style="font-size: 25px; text-transform: uppercase; font-weight: 700;">{{title ?? ''}}</span>
              </div>
            </div>
        </div>
        <div class="d-md-flex align-items-center justify-content-end" >
          <button type="button" *ngIf="HasAddButton()" (click)="AddClick()" class="btn btn-cyan p-2">
            <i class="fas fa-plus  font-14 mr-1 text-white"></i>
            <span class="ml-1 text-white font-weight-bold">Aggiungi</span>
          </button>
          <ng-container *ngIf="hasListActions()">
            <ng-container *ngFor="let button of CrudSettings['AzioniLista']">
              <button type="submit" type="button" [ngClass]="button['class']" [ngbTooltip]="button.placeholder ?? ''"
                      *ngIf=" !button.single && campoUtility.ButtonShow(button,Item)"
                      (click)='MassiveButtonClick(button)'>
                <i *ngIf="button['icon']" [ngClass]="button['icon']" class="font-14 mr-1 text-white"></i>
                <span [ngClass]="button['labelClass']"> {{ button['nome'] | translate }} </span>
              </button>
            </ng-container>
          </ng-container>
        </div>
      </div>
        
        <!-- Filtri normali -->
        <div class="card" *ngIf=" !FilterViewType || FilterViewType == 'normal' ">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <app-field-viewer *ngIf="CrudSettings" [(Campi)]="CrudSettings.Filters" [(Oggetto)]="filtro" (OnChange)="Refresh()"></app-field-viewer>
                </div>
              </div>
            </div>
        </div>

        <!-- Lista -->
        <div class="table-responsive">
            <table class="table bg-white table-hover table-fixed align-middle mb-0 ">
              <thead class="fi">
                <tr *ngIf="dynamicHeader()?.length > 0">
                    <th *ngIf="hasListActions()">
                        <a href="javascript:void(0)" class="link" (click)="toggleAll('selected', scadenze)">{{allSelected('selected', scadenze) ? 'Deseleziona tutto' : 'Seleziona tutto'}}</a>
                    </th>
                    <ng-container *ngFor="let campo of dynamicHeader()">
                        <th scope="col bg-white"  (click)="order(campo)" *ngIf="headShow(campo)">{{campo.nome}}</th>
                    </ng-container>
                    <th scope="col">Azioni</th>
                </tr>
              </thead>
              <tbody *ngIf="dynamicHeader()?.length > 0 ">
                <tr *ngFor="let item of List " [ngbTooltip]="this.Tooltip(item)">
                    <td *ngIf="hasListActions()">
                        <input [(ngModel)]="item['selected']" type="checkbox" />
                    </td>
                    <ng-container *ngFor="let campo of dynamicHeader()">
                      <td class="align-middle" (click)='RowClick(item)' *ngIf="headShow(campo)">
                        <app-dynamic-list-row  [item]="item" [campo]="campo" ></app-dynamic-list-row>
                      </td>
                    </ng-container>
                    
                    <td class="align-middle">
                        <ng-container *ngIf="CrudSettings">
                          <ng-container *ngFor="let button of CrudSettings['AzioniLista']">
                            <a href="javascript:void(0)" [ngClass]="button['class']" [ngbTooltip]="button.placeholder ?? ''" *ngIf="campoUtility.ButtonShow(button,Item)"
                                    (click)='ButtonClick(item, button)'>
                                <i *ngIf="button['icon']" [ngClass]="button['icon']" class="font-14 mr-1"></i>
                            </a>
                          </ng-container>
                        </ng-container>
                        <a href="javascript:void(0)" [ngbTooltip]="'Modifica'" class="link font-16 text-info font-medium mr-2" (click)='EditButton(item)'>
                          <i-feather name="edit-3"  class="feather-sm"></i-feather>
                        </a>   
                    </td>
                </tr>
              </tbody>
            </table>
        </div>
    </div>
</div>