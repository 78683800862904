<div class="email-container px-3">
  <div class="row">
    <div class="col-md-3 col-lg-3 col-xl-2 bg-white border-right">
      <div class=" p-2" style="background-color: rgba(21, 0, 254, 0.502);" >
        <div class="d-flex justify-content-end">
          <a href="javascript:void(0)" (click)="AbilitaModifiche()" *ngIf="permission.isPermitted('gestione-formazione')" class="btn bg-white text-success py-1 px-2 link" placement="bottom"
             ngbTooltip="Abilita Modifiche" >
            <i class="fas fa-pencil-alt"></i>
          </a>
          <a href="javascript:void(0)" *ngIf="permission.isPermitted('gestione-formazione')" (click)="openModal(editTicketModal, null)" placement="bottom" ngbTooltip="Aggiungi" class="btn bg-white text-info py-1 px-2 link " >
            <i-feather name="plus" class="feather-sm"></i-feather>
          </a>
        </div>
      </div>
      <div class="inbox-panel p-2 bg-white" >
        <ul class="list-group custom-group message-center" id="sidebar">
          <li class=" list-group-item " style="padding-right: 0; padding-bottom: 0;" *ngFor="let sidebarnavItem of categorie">
            <a href="javascript:void(0)" (click)="clicked(sidebarnavItem)" class="message-item d-flex align-items-center border-0 font-weight-normal">
              <i [class.text-info]="sel_cat_id === sidebarnavItem.id" [class.fa-folder-open]="sel_cat_id === sidebarnavItem.id" [class.fa-folder]="sel_cat_id !== sidebarnavItem.id" class="far font-18 mr-2"></i>
              {{sidebarnavItem.categoria}}
              <div *ngIf="modificheAbilitate" class="ml-auto">
                <a href="javascript:void(0)" (click)="openModal(editTicketModal, sidebarnavItem)" class="btn bg-white text-success py-1 px-2 link" placement="bottom" ngbTooltip="Modifica" >
                  <i class="fas fa-pencil-alt"></i>
                </a>
                <a href="javascript:void(0)" (click)="openModal(DeleteCategoriaModal, sidebarnavItem)" class="btn bg-white text-danger py-1 px-2 link" placement="bottom" ngbTooltip="Elimina" >
                  <i-feather name="trash-2" class="feather-sm"></i-feather>
                </a>
              </div>
            </a>
            <ul *ngIf="sidebarnavItem.figli && sidebarnavItem.figli.length > 0" class="list-group custom-group message-center" id="sidebar">
              <!-- Second level menu -->
              <li class="list-group-item  " style="padding-right: 0; padding-bottom: 0;"
                  *ngFor="let item of sidebarnavItem.figli">
                <a href="javascript:void(0)" (click)="clicked(item)"
                   class="message-item d-flex align-items-center border-0  font-weight-normal text-dark">
                  {{item.categoria}}
                  <div *ngIf="modificheAbilitate" class="ml-auto">
                    <a href="javascript:void(0)" (click)="openModal(editTicketModal, item)" class="btn bg-white text-success py-1 px-2 link" placement="bottom"
                       ngbTooltip="Modifica" >
                      <i class="fas fa-pencil-alt"></i>
                    </a>
                    <a href="javascript:void(0)" (click)="openModal(DeleteCategoriaModal, item)" class="btn bg-white text-danger py-1 px-2 link" placement="bottom"
                       ngbTooltip="Elimina" >
                      <i-feather name="trash-2" class="feather-sm"></i-feather>
                    </a>
                  </div>
                </a>
                <ul *ngIf="item.figli && item.figli.length > 0" class="list-group custom-group message-center" id="sidebar">
                  <!-- Second level menu -->
                  <li class="list-group-item  " style="padding-right: 0; padding-bottom: 0;"
                      *ngFor="let item3 of item.figli">
                    <a href="javascript:void(0)" (click)="clicked(item3)"
                       class="message-item d-flex align-items-center border-0  font-weight-normal text-dark">
                      {{item3.categoria}}
                      <div *ngIf="modificheAbilitate" class="ml-auto">
                        <a href="javascript:void(0)" (click)="openModal(editTicketModal, item3)" class="btn bg-white text-success py-1 px-2 link" placement="bottom" ngbTooltip="Modifica" >
                          <i class="fas fa-pencil-alt"></i>
                        </a>
                        <a href="javascript:void(0)" (click)="openModal(DeleteCategoriaModal, item3)" class="btn bg-white text-danger py-1 px-2 link" placement="bottom" ngbTooltip="Elimina">
                          <i-feather name="trash-2" class="feather-sm"></i-feather>
                        </a>
                      </div>
                    </a>
                    <ul *ngIf="item3.figli && item3.figli.length > 0" class="list-group custom-group message-center" id="sidebar">
                      <!-- Second level menu -->
                      <li class="list-group-item  " style="padding-right: 0; padding-bottom: 0;"
                          *ngFor="let item4 of item3.figli">
                        <a href="javascript:void(0)" (click)="clicked(item4)"
                           class="message-item d-flex align-items-center border-0  font-weight-normal text-dark">
                          {{item4.categoria}}
                          <div *ngIf="modificheAbilitate" class="ml-auto">
                            <a href="javascript:void(0)" (click)="openModal(editTicketModal, item4)" class="btn bg-white text-success py-1 px-2 link" placement="bottom" ngbTooltip="Modifica" >
                              <i class="fas fa-pencil-alt"></i>
                            </a>
                            <a href="javascript:void(0)" (click)="openModal(DeleteCategoriaModal, item4)" class="btn bg-white text-danger py-1 px-2 link" placement="bottom" ngbTooltip="Elimina">
                              <i-feather name="trash-2" class="feather-sm"></i-feather>
                            </a>
                          </div>
                        </a>
                        
                        </li>
                    </ul>
                    </li>
                </ul>
              </li>
                </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="p-3 col-md-9 col-lg-9 col-xl-10 bg-white">
      <div class="inbox-right-panel">
        <app-check-list-shared #checkListComponent  [cat_id]="sel_cat_id" ></app-check-list-shared>
      </div>
    </div>
  </div>
</div>









<ng-template #editTicketModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">Domanda</h5>
    <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="input-group col-12">
        <div class="">
          <label class="input-group-text" >Nome Domanda:</label>
        </div>
        <input type="text" [(ngModel)]="Selected.categoria" name="nome" class="form-control">
      </div>
      <div class="col-12">
        <label class="control-label col-form-label">Categorie Padre:</label>
        <ng-multiselect-dropdown style="width: 80%; " [placeholder]="'Seleziona'" name="categorie" [data]="CategorieLineare"
                                 [(ngModel)]="Selected.Cat" [settings]="categoriedropdownSettings">
        </ng-multiselect-dropdown>
      </div>
      <div class="input-group col-12">
        <div class="">
          <label class="input-group-text" >Ordine:</label>
        </div>
        <input type="number" [(ngModel)]="Selected.ordine" name="nome" class="form-control">
      </div>
    </div>
    <div class="d-md-flex align-items-center p-3" style="padding-right: 25px;">
      <button type="submit" (click)="saveModalButton()" class="ml-auto btn btn-rounded btn-success p-2 waves-effect waves-light">
        <span class="ml-1 text-white font-weight-bold"> Salva </span>
        <i-feather name="save" class="feather-md "></i-feather>
      </button>
    </div>
  </div>
</ng-template>


<ng-template #DeleteCategoriaModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" >Elimina Domanda</h5>
    <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="d-md-flex align-items-center p-3" style="padding-right: 25px; margin-top: 25px;">
      <button type="submit" (click)="Elimina(Selected)"
              class="ml-auto btn btn-rounded btn-danger p-2 waves-effect waves-light">
        <span class="ml-1 text-white font-weight-bold"> Elimina </span>
        <i-feather name="trash-2" class="feather-md "></i-feather>
      </button>
    </div>
  </div>
</ng-template>
