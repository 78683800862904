import { DatePipe } from "@angular/common";
import {EventEmitter, Injectable} from "@angular/core";
import { environment } from "src/environments/environment";
import {IDropdownSettings} from "../../../LibrerieCustom/MultiselectDropdown";
import {Permission} from "../../Login/_guards/Permission";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: 'root' })
export class  CampoUtility {
  environment = environment;
  constructor( public permission: Permission, private datepipe: DatePipe, private Toastr: ToastrService) {
  }


  private checkValue(val: any, oggetto: any, type: string = null ){
    return (!val?.fieldexist || (!( typeof(val?.fieldexist) == "string" &&  this.getObj(oggetto, val?.fieldexist)) && !( Array.isArray(val?.fieldexist)  &&  val?.fieldexist.some(x=> oggetto[ this.getObj(oggetto,x)]) ))) &&
      (!val?.fieldnotexist || (( typeof(val?.fieldnotexist) == "string" &&  this.getObj(oggetto, val?.fieldnotexist)) || ( Array.isArray(val?.fieldnotexist)  &&  val?.fieldnotexist.some(x=> this.getObj(oggetto,x)) ))) &&
      (!val?.hasCapacity || this.permission.HasnotCapacity(val?.hasCapacity)) &&
      (!val?.hasNotCapacity || this.permission.HasCapacity(val?.hasNotCapacity)) &&
      (!val?.hasRoles || !this.permission.isOperativeRoles(val?.hasRoles)) &&
      (!val?.hasNotRoles || this.permission.isOperativeRoles(val?.hasNotRoles)) &&
      (!val?.fieldNotEqual || !val?.fieldNotEqual?.some(x=> oggetto[x.source] != x.value && oggetto[x.source] != oggetto[x.internalParameter])) &&
      (!val?.fieldEqual ||  val?.fieldEqual?.some(x=> !oggetto[x.source] || (oggetto[x.source] != x.value && oggetto[x.source] != oggetto[x.internalParameter]))) &&
      (!val?.field?.field || oggetto[val.field.field] == val.field.notValue) &&
      (!val?.typeIs || type == null || !val?.typeIs.includes(type)) &&
      (!val?.typeIsNot || val?.typeIsNot.includes(type)) &&
      (!val?.arrayEmpty || (!oggetto[val?.arrayEmpty] || oggetto[val?.arrayEmpty]?.length >= 0)) &&
      (!val?.arrayFull || oggetto[val?.arrayFull]?.length>0)

    }

  public CheckValue(val: any, oggetto: any, type: string = null, matchType: boolean = true ){
    var condizione = 
      (!val?.fieldexist || (!( typeof(val?.fieldexist) == "string" &&  this.getObj(oggetto, val?.fieldexist)) && !( Array.isArray(val?.fieldexist)  &&  val?.fieldexist.some(x=> oggetto[ this.getObj(oggetto,x)]) ))) &&
      (!val?.fieldnotexist || (( typeof(val?.fieldnotexist) == "string" &&  this.getObj(oggetto, val?.fieldnotexist)) || ( Array.isArray(val?.fieldnotexist)  &&  val?.fieldnotexist.some(x=> this.getObj(oggetto,x)) ))) &&
      (!val?.hasCapacity || this.permission.HasnotCapacity(val?.hasCapacity)) &&
      (!val?.hasNotCapacity || this.permission.HasCapacity(val?.hasNotCapacity)) &&
      (!val?.hasRoles || !this.permission.isOperativeRoles(val?.hasRoles)) &&
      (!val?.hasNotRoles || this.permission.isOperativeRoles(val?.hasNotRoles)) &&
      (!val?.fieldNotEqual || !val?.fieldNotEqual?.some(x=> oggetto[x.source] != x.value && oggetto[x.source] != oggetto[x.internalParameter])) &&
      (!val?.fieldEqual ||  val?.fieldEqual?.some(x=> !oggetto[x.source] || (oggetto[x.source] != x.value && oggetto[x.source] != oggetto[x.internalParameter]))) &&
      (!val?.field?.field || oggetto[val.field.field] == val.field.notValue) &&
      (!val?.typeIs || type == null || !val?.typeIs.includes(type)) &&
      (!val?.typeIsNot || val?.typeIsNot.includes(type)) &&
      (!val?.arrayEmpty || (!oggetto[val?.arrayEmpty] || oggetto[val?.arrayEmpty]?.length >= 0)) &&
      (!val?.arrayFull || oggetto[val?.arrayFull]?.length>0);

    return (!val || (!val?.fieldexist && !val?.fieldnotexist && !val?.hasCapacity && !val?.hasNotCapacity && !val?.hasRoles && !val?.hasNotRoles && !val?.fieldNotEqual && !val?.fieldEqual && !val?.field?.field &&(!val?.typeIs || type == null) && !val?.typeIsNot && !val?.arrayEmpty && !val?.arrayFull )) ? matchType : (matchType ? !condizione : condizione)

  }


  public getDynamicTaskSctructure(taskType?:string, tipo?: string){
    const result = this.environment.TaskAddon['DynamicFields']?.find(x=>x.type == taskType && (!tipo || tipo == '0' || tipo == x['tipo'] || !this.environment.TaskAddon['DynamicFields']?.find(y => y['tipo'] == tipo)));
    // console.log("DynamicFields",this.environment.TaskAddon['DynamicFields']);
    // console.log("taskType: ",taskType);
    // console.log("tipo: ",taskType);
    // console.log("Result: ",result);
    return result
  }
  public ValidateError(oggetto, campi){
    if(!campi) return true;
    var campiNonValidati = this.Validate(campi, oggetto);
    if(campiNonValidati?.length > 0){
        var errore = "";
        campiNonValidati.forEach(x=>{
          errore+=`Il campo ${x} è richiesto;\n`
        })
        this.Toastr.error(`${errore}`,`Inserire i campi richiesti`);
        return false;
    }
    return true;
  }
  Validate(sezioni: any[], oggetto){
      var campiNonValidati = [];
      if(sezioni.some(x=> x.campi))
        sezioni?.filter(x=>this.Show(x, oggetto,true ) ||this.Show(x, oggetto,false ) )?.forEach(sezione => {
          campiNonValidati = [...campiNonValidati, ...this.ValidateCampi(sezione.campi, oggetto)];
        });
      else
        campiNonValidati = this.ValidateCampi(sezioni, oggetto);
      return campiNonValidati;
    }

  ValidateCampi(campi: any[], oggetto){
      return campi?.filter(campo => this.Show(campo, oggetto,true ) && this.Required(campo,oggetto) && !this.is_Valid(campo,oggetto)).map(x=>x.nome) ?? [];
      //return ["ciao"];
  }
  is_Valid(campo,oggetto){
      return !this.getValues(campo,oggetto)?.some(x=> !x || (Array.isArray(x) && !(x.length > 0)));
  }
    public Show(campo: any, oggetto: any, modificabile = true, type: string = null, campiNascosti:any[] = []): boolean {
    return (campo?.hideif?.SAvisible && this.permission.IAmYourFather()) ||  (
      !campiNascosti?.find(x=>x == campo.nome) &&
      (campo.modificabile == modificabile || (!modificabile && !campo.modificabile) ) && (
        campo.visibleifProhibited || (
          this.permission.isOperativeRoles(campo.AllowedRoles) &&
          this.permission.isntOperativeRoles(campo.prohibitedRoles) &&
          this.permission.HasCapacity(campo.allowedCapacity)
        )
      )
      &&
      (!campo.hideif || this.checkValue(campo?.hideif, oggetto, type))
    )
  }
  public ButtonShow(campo: BottoneAggiuntivo, oggetto: any): boolean {
    return (campo?.hideif?.SAvisible && this.permission.IAmYourFather()) ||  (
      campo.visibleifProhibited || (
        this.permission.isOperativeRoles(campo.AllowedRoles) &&
        this.permission.isntOperativeRoles(campo.prohibitedRoles) &&
        this.permission.HasCapacity(campo.allowedCapacity)
      )
    ) && (
      !campo.hideif || this.checkValue(campo?.hideif, oggetto)
    )
  }
  public ListShow(campo: CampoAggiuntivo, oggetto: any): boolean {
    return campo.viewInList && ((campo?.hideif?.SAvisible && this.permission.IAmYourFather()) || ((
      campo.visibleifProhibited || (
        this.permission.isOperativeRoles(campo.AllowedRoles) &&
        this.permission.isntOperativeRoles(campo.prohibitedRoles) &&
        this.permission.HasCapacity(campo.allowedCapacity)
      )
    ) && (
      !campo.hideif || this.checkValue(campo?.hideif, oggetto)
    )))
  }
  public  Disable(campo: CampoAggiuntivo, oggetto: any): boolean{
    return !(campo?.disabledif?.SAvisible && this.permission.IAmYourFather()) && (
      (campo?.AllowedRoles && this.permission.isntOperativeRoles(campo.AllowedRoles)) ||
      (campo?.prohibitedRoles && this.permission.isOperativeRoles(campo.prohibitedRoles)) ||
      (campo?.allowedCapacity && !this.permission.HasCapacity(campo.allowedCapacity)) ||
        !this.checkValue(campo?.disabledif, oggetto)
    )
  }
  public Required(campo: CampoAggiuntivo, oggetto: any):boolean{
    return (
      campo.required ||
      (
        campo.requiredif && 
        !this.checkValue(campo?.requiredif, oggetto)
      )
    ) && 
    (
      (
        (
          campo.type != 'object' && 
          !Array.isArray(campo.internalOutput)
          /*&& !this.DropdownSources[campo.externalOutput] */&&
          (
            !oggetto[campo.internalOutput] ||
            (
              !campo.settings.singleOutput && 
              !(oggetto[campo.internalOutput]?.length >0)
            )
          )
        ) ||
        (
          campo.type == 'object' && 
          !Array.isArray(campo.internalOutput) &&
          !oggetto[campo.internalOutput]
        )
      ) ||
      (
        campo.inputType != 'singleDropdown' &&
        campo.inputType != 'multiselectDropdown'
      )
    )
  }
  public Warning(campo: CampoAggiuntivo, oggetto: any):boolean{
    return (campo?.warnif && this.checkValue(campo?.warnif, oggetto)) || (!campo?.warnif && campo?.warning && !(this.getValues(campo, oggetto)?.toString()?.length > 0))

  }
  public WarningText(campo: CampoAggiuntivo, oggetto: any): string {
    var mex = typeof campo.warning == 'string' &&  campo.warning?.length > 0 ? campo.warning : `Attenzione il campo ${campo.nome} non è compilato`;
    return this.Warning(campo, oggetto) ? mex : '';
  }
  public getValuesFromArray(campi: CampoAggiuntivo[], item: any): string[] {
    var array: string[] = [];
    campi?.forEach(campo => {
      array = array.concat(this.getValues(campo,item) ?? [])
    })
    return array;
  }

  public getValues(campo: CampoAggiuntivo, item: any): string[] {
    var array: string[] = [];
    campo.oggetti.forEach((oggetto, i) => {
      if(campo.type!= 'object' && campo.inputType == 'date' && item[oggetto])
        array.push(this.getObject(campo,item,i))
      if(campo.type == 'object' && campo.inputType == 'date')
        array.push(this.getObject(campo,item,i))
      if(campo.type!= 'object' && campo.inputType != 'date' && campo.type != 'replace')
        array.push(this.getObject(campo,item,i))
      if(campo.type == 'object' && campo.inputType != 'date' && item[oggetto])
        array.push(this.getObject(campo,item,i))
      if(campo.type == 'object' && campo.inputType != 'date' && !item[oggetto] && campo.oggettisec && campo.oggettisec[i] && item[campo.oggettisec[i]])
        array.push(item[campo.oggettisec[i]][campo.field[i]])
      if(campo.type == 'replace')
        array.push(this.Replace(campo,item, oggetto))
    })
    return array;
  }


  public getValueString(campo: CampoAggiuntivo, item: any): string {
   var campi = this.getValues(campo,item);
   var outputString: string = '';
   campi.forEach(string => {
     outputString += string;
     outputString += ' ';
   })
    return outputString;
  }
  public getValueStringFromArray(campi: CampoAggiuntivo[], item: any): string {
    var outputString: string = '';
    this.getValuesFromArray(campi,item)?.forEach(string => {
      outputString += string;
      outputString += ' ';
    })
    return outputString;
  }
  
  public Colore(campo: CampoAggiuntivo, oggetto: any){
    var color ;
    campo?.color?.forEach(colore =>{
      if( (colore?.fieldexist && oggetto[colore?.fieldexist]) ||
        (colore?.fieldnotexist && !oggetto[colore?.fieldnotexist]) ||
        (colore?.boolTrue && oggetto[colore?.boolTrue] == true) ||
        (colore?.boolFalse && oggetto[colore?.boolFalse] == false) ||
        (colore && colore['expiredDate'] && oggetto[colore['expiredDate']] && this.expired(oggetto[colore['expiredDate']])) ||
        (colore?.arrayEmpty && (!oggetto[colore?.arrayEmpty] || oggetto[colore?.arrayEmpty]?.length >= 0)) ||
        (colore?.arrayFull && oggetto[colore?.arrayFull]?.length>0)) {
        color = colore.colore.startsWith('#') ? colore.colore : oggetto[colore.colore];
      }
    })

   return color;
  }

  private expired(data: string):boolean{
    var data2 = new Date(data);
    var data1 = new Date();
      const timeDiff = Math.abs(data2.getTime() - data1.getTime())
      const diffInDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

      return diffInDays > 0;

  }
  public Replace(campo: CampoAggiuntivo, oggetto: any, objsel:string){
    var replaced = this.getObj(oggetto,objsel);
    campo?.replacement?.forEach(replacement =>{
      if( ((!replacement?.fieldexist || oggetto[replacement?.fieldexist]) &&
        (!replacement?.fieldnotexist || !oggetto[replacement?.fieldnotexist]) &&
        (!replacement?.arrayEmpty || (!oggetto[replacement?.arrayEmpty] || oggetto[replacement?.arrayEmpty]?.length >= 0)) &&
        (!replacement?.arrayFull || oggetto[replacement?.arrayFull]?.length>0)) && (oggetto[objsel] ?? objsel) == replacement.original)
        replaced = replacement.replace;
    })
    return replaced;
  }
  public getObj(oggetto, campo){
    var p = campo?.split('.');
    var parameter = Object.assign({}, oggetto)
    p?.forEach(el=>{
      parameter = this.getparam(parameter,el);
    })
    return parameter
  }
  public getparam(oggetto, param){
    return oggetto ? oggetto[param]: null;
  }
  public getChecklistPrice(value ){
    console.log("Attività: ", value);
    var price: number = 0;
    value?.filter(x=>x.risposta)?.forEach(check =>{
      console.log("Check: ", check);
      check?.fields?.forEach(field=>{
        if (field.inputType == "number") {
          var valore = parseInt(check[field['oggetti'][0]]?.toString() ?? '0');
          console.log("Valore", valore);
          switch (check.codice) {
            case "B":
              price += this.getFieldPrice(field, valore);
              break;
            case "A":
              price += this.getFieldPrice(field, valore);
              break;
            default:
              price += (this.getFieldPrice(field, valore) * valore);
              break;
          }

          price += check.codice == "S" ? 50 : 0;
          console.log("Field: ", field);
          console.log("Prezzo: ", price);
        }else if ( field.inputType == 'singleDropdown'){
          console.log("Field: ", field);
          console.log("Selected", check[field['OutputObject']]);
          console.log("Price", check[field['OutputObject']]?.prezzo);
          price += check[field['OutputObject']]?.prezzo ?? 0;
        }
      })
    })
    console.log("Prezzo Complessivo: ", price);
    return price;
  }

  getFieldPrice(field: CampoAggiuntivo, valore: number){
    var tariffa = field?.tariffe?.find(x=> (x.min == null || x.min <= valore) && (x.max == null || x.max >= valore));
    console.log("foundRule", tariffa);
    return tariffa?.prezzo ?? 0;
  }
  getObject(campo: CampoAggiuntivo, obj:any, i?:number){
    if(campo.type == 'object' && obj[campo.oggetti[i]] && obj[campo.oggetti[i]][campo.field[i]] && typeof obj[campo.oggetti[i]][campo.field[i]] !== 'string' )
      return  campo.field[i+1] ? obj[campo.oggetti[i]][campo.field[i]][campo.field[i+1]] : {}
    else if(campo.inputType == "singleDropdown")
      return obj[campo.OutputField];
    else if (obj[campo.oggetti[i]] && (campo.type == 'object' || (typeof obj[campo.oggetti[i]] !== 'string' && typeof obj[campo.oggetti[i]] !== 'number' && typeof obj[campo.oggetti[i]] !== 'boolean')))
      return obj[campo.oggetti[i]][campo.field[i]]
    else
      return obj[campo.oggetti[i]]
  }
  getSortedList(input: any[], campo: CampoAggiuntivo, order: 'asc'|'desc'): any[]{

   var output = Object.assign([],input);
   if((!campo.type || campo.type == 'string') && campo.inputType != 'date' && campo.inputType != 'datetime-local'){
    output = output.sort((a, b) => {
      return ((this.getObject(campo, a,0)?.toLowerCase() ?? '0') < (this.getObject(campo, b,0)?.toLowerCase() ?? '0'))
        ? (order == 'asc' ? -1 : 1)
        : (order == 'asc' ? 1 : -1)
    })
   }
    else if(campo.inputType == 'date' || campo.inputType == 'datetime-local'){
      output = output.sort((a,b)=>{
        return (new Date(this.getObject(campo, a,0) ?? '2000-01-01') > new Date(this.getObject(campo, b,0) ?? '2000-01-01') )? (order == 'asc' ? 1 : -1) : (order == 'asc' ? -1 : 1)
      })
    }
    else if(campo.type == 'object' && (!campo.inputType || campo.inputType == 'text')){
      output = output.sort((a, b) => {
        return this.getObject(campo, a,0)?.toLowerCase() < this.getObject(campo, b,0)?.toLowerCase()
          ? (order == 'asc' ? -1 : 1)
          : (order == 'asc' ? 1 : -1)
      })
    }
    return output;
  }


  getFilteredList(list: any[], campiAggiuntivi, filters: any): any[] {
    var output = [...list];
    campiAggiuntivi.forEach(campo=> output = this.FilterList(output,campo, filters))
    return output;
  }
  FilterList(list: any[], campoAggiuntivo, filters: any): any[] {
    var filter = this.getObject(campoAggiuntivo,filters,0)
    if(filter && ((campoAggiuntivo.inputType == null && campoAggiuntivo.type == null) || campoAggiuntivo.inputType == "text" || campoAggiuntivo.type=="string") )
      return list.filter(obj => this.getObject(campoAggiuntivo,obj,0)?.toLowerCase().includes(filter.toLowerCase()));
    else if(filter)
      return list.filter(obj => this.getObject(campoAggiuntivo,obj,0) == filter);
    return list
  }
}




export class CampoAggiuntivo {
  nome?: string;
  oggetti?: string[];
  replacement?: {original?: string, fieldexist?:string, fieldnotexist?: string,arrayEmpty?:string, arrayFull?:string, replace?:string}[];
  color?: {colore: string, fieldexist:string, fieldnotexist?: string,arrayEmpty?:string, arrayFull?:string, boolFalse?:string, boolTrue?:string}[]
  modificabile?: boolean;
  prevDivClass?: string;
  inputType?:  'text' | 'checkbox' | 'number' | 'singleDropdown' | 'multiselectDropdown' | 'color' | 'date' | 'datetime-local' | 'email' | 'tel' | 'textarea' | 'password' | 'euro' | 'html' | 'file' |string;
  required?: boolean;
  suffix?: string;
  prefix?: string;
  viewInList?:boolean;
  tasktype?:string;
  responseHasData?:boolean;
  parameter?: {nome?:string, value?: any, parameter?: string, search?: boolean}[]
  HeaderLista?:boolean;
  tariffe?: {max?:number, min: number, prezzo: number}[];
  requiredif?: CheckObject;
  disabledif?: CheckObject;
  hideif?: CheckObject;
  icon?: string;
  type?: 'replace' | 'string' | 'boolean' | 'object' | 'ArrayCampiAggiuntivi' | 'checkboxArray' | 'checkboxArrayMEXC' | 'Files' | string ;
  field?: string[];
  class?: string;
  source?: string | any[];
  sourceFilter?:{
    fieldexist?: string,
    fieldnotexist?: string,
    arrayEmpty?:string,
    arrayFull?:string,
    prohibitedRoles?: string[];
    AllowedRoles?: string[];
    allowedCapacity?: string[];
    prohibitedCapacity?: string[];
  }
  collapsable?: boolean;
  collapsed?:boolean;
  MathOperation?: {MathType: '+' | '-', NumType: 'number' | 'percentage'};
  internalOutput?: string | any[]; //ex. clienteObject
  externalOutput?: string | any[]; //ex. clienteObject
  OutputField?: string; // ex. id
  OutputObject?: string; // ex. client
  OnFilter?: string;
  OnChange?: string;
  settings?: IDropdownSettings;
  style?: { [klass: string]: any; };
  titleClass?: string;
  titleStyle?: { [klass: string]: any; };
  inputClass?: string;
  inputStyle?: { [klass: string]: any; };
  campiAggiuntivi?: CampoAggiuntivo[];
  placeholder?: string;
  visibleifProhibited?: boolean ;
  prohibitedRoles?: string[];
  AllowedRoles?: string[];
  allowedCapacity?: string[];
  nextDivClass?: string;
  url?: string; //url (file upload)
  AddClick?: EventEmitter<any>;
  ExternalAddClick?: string;
  header?: CampoAggiuntivo[];
  azioni?: BottoneAggiuntivo[];
  oggettisec?: any;
  GestioneFile?: any;
  multipart?: boolean;
  imageToServer?: string;
  filename?: string;
  warning?: string | boolean;
  warnif?:CheckObject;
  attr?:any;
  searched?: boolean;
  nameField?: string;
}


export class CheckObject {
  fieldexist?: string | string[];
  fieldnotexist?: string | string[];
  field?:{field: string, notValue:any};
  SAvisible?: boolean;
  fieldNotEqual?: {source: string, value: any, externalParameter: string, internalParameter: string}[];
  hasCapacity?: string[];
  hasNotCapacity?: string[];
  hasNotRoles?: string[];
  hasRoles?: string[];
  typeIs?: string[];
  typeIsNot?: string[];
  arrayEmpty?:string;
  arrayFull?:string;
  fieldEqual?: {source: string, value: any, externalParameter: string, internalParameter: string}[];
}



export class Sezione {
  prohibitedRoles?: string[];
  allowedRoles?: string[];
  prohibitedCapacity?: string[];
  allowedCapacity?: string[];
  sezione: string;
  campi: CampoAggiuntivo[];
  campo?: string;
  class?: string;
  style?: { [klass: string]: any; };
  titleClass?: string;
  titleStyle?: { [klass: string]: any; };
  bodyClass?: string;
  bodyStyle?: { [klass: string]: any; };
}
export class BottoneAggiuntivo {
  prohibitedRoles?: string[];
  prohibitedCapacity?: string[];
  visibleifProhibited?: boolean ;
  AllowedRoles?: string[];
  allowedCapacity?: string[];
  hideif?: {
    fieldexist?: string | string[],
    fieldnotexist?: string | string[],
    field?:{field: string, notValue:any},
    SAvisible?: boolean,
    fieldNotEqual?: {source: string, value: any, externalParameter: string, internalParameter: string}[],
    hasCapacity?: string[],
    hasNotCapacity?: string[],
    hasNotRoles?: string[],
    hasRoles?: string[],
    typeIs?: string[],
    typeIsNot?: string[],
  };
  icon?: string;
  class?: string;
  style?: { [klass: string]: any; };
  titleClass?: string;
  titleStyle?: { [klass: string]: any; };
  onClick: EventEmitter<any>;
  tooltip?: string;
  color: string;
  ExternalOnClick: string;

}
