import { EventEmitter, Injectable, Input, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ApiPathsService } from "src/app/api-paths.service";
import { NewsComponent } from "../news/news.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CampoAggiuntivo, CampoUtility } from "src/app/shared/models/campo-aggiuntivo";
import { AuthenticationService } from "src/app/Login/_services/authentication.service";
import { DatePipe } from "@angular/common";
import { Task } from "../models/Task";
import { ActionButton, DynamicCrudService } from "src/app/shared/_services/dynamic-crud.service";

@Injectable({ providedIn: 'root' })
export class TaskService {
  constructor(
    private api: ApiPathsService,
    private Toastr: ToastrService,
    private modalService: NgbModal,
    private campoUtility: CampoUtility,
    private authSvc: AuthenticationService,
    private datepipe: DatePipe,
    private CrudService: DynamicCrudService
  ) { }
  @Output() Refresh = new EventEmitter<any>();
  @Output() updated = new EventEmitter<Task>();
  @Input() ExternalAdd: boolean = false;
  InitObject(Item, DynamicFields) {
    this.CrudService.InitObject(Item, DynamicFields);
  }
  addDynamicButton(dynamicField, Item, concat?: { from: string, to: string, value: any }[], copy?: { from: string, to: string, value: any }[],) {
    this.CrudService.CreateRelatedTask(dynamicField, Item, concat, copy, (data) => {
      this.Refresh.emit(data);
    })
  }
  getparam(oggetto, param, GetArrayFirstObj: boolean = false) {
    return oggetto ? ((Array.isArray(oggetto[param]) && GetArrayFirstObj) ? (oggetto[param]?.length > 0 ? oggetto[param][0] : null) : oggetto[param]) : null;
  }
  HandleTasksCustomButtonClick(Items: any[], button: ActionButton, FromLista: boolean) {
    if (!(Items?.length > 0) || (button?.preAlert && !confirm(button.preAlert))) return;
    if (button.restRoute?.includes(':token'))
      button.restRoute = button.restRoute.replace(':token', this.authSvc.currentUserValue?.access_token)
    if (button.preMex)
      this.Toastr.warning(button.preMex);
    var firstTask = Items[0]
    var Structure = this.campoUtility.getDynamicTaskSctructure(button.taskType ?? firstTask.type, button.taskType ? button.taskFilter : firstTask.tipo)
    var TaskEdited;
    if (button.type == 'edit' && button.campi) {
      var structure = Object.assign({}, Structure);
      structure.campi = button.campi;
      const editmodal = this.modalService.open(NewsComponent, {
        centered: true,
        backdrop: 'static',
        size: 'xl',
      });
      (<NewsComponent>editmodal.componentInstance).DynamicFields = structure;
      (<NewsComponent>editmodal.componentInstance).ExternalAdd = true;
      (<NewsComponent>editmodal.componentInstance).IsModal = true;
      (<NewsComponent>editmodal.componentInstance).Item = {};
      (<NewsComponent>editmodal.componentInstance).FromLista = FromLista;
      (<NewsComponent>editmodal.componentInstance).updated.subscribe(data => {
        TaskEdited = data;
        Items.forEach(task => {
          this.TaskCutomButtonExecute(Structure, task, TaskEdited, button, FromLista);
        });
        //this.save(data,structure);
      });
    }
    else
      Items.forEach(task => {
        this.TaskCutomButtonExecute(Structure, task, {}, button, FromLista);
      });


  }
  //Gestione azioni dinamiche
  HandleTaskCustomButtonClick(Item, button: ActionButton, FromLista: boolean) {
    if (Item['clientiObject'])
      Item['clienti'] = Item['clientiObject'].map(x => x.id);
    if ((button?.preAlert && !confirm(button.preAlert))) return;
    if (button.restRoute?.includes(':token'))
      button.restRoute = button.restRoute.replace(':token', this.authSvc.currentUserValue?.access_token)
    if (button.preMex)
      this.Toastr.warning(button.preMex);
    var firstTask = Item
    var Structure = this.campoUtility.getDynamicTaskSctructure(firstTask.type, firstTask.tipo)
    console.log('Item: ', Item);
    this.TaskCutomButtonExecute(Structure, Item, {}, button, FromLista, true);
  }
  TaskCutomButtonExecute(Structure, task: any, TaskEdited, button: ActionButton, FromLista: boolean, ShowEditModal: boolean = false) {
    var filter = {};
    button.parameter?.forEach(param => {
      var p = param?.parameter?.split('.');
      var parameter = Object.assign({}, task)
      p?.forEach(el => {
        parameter = this.getparam(parameter, el);
      })
      filter[param.nome] = param.value ?? parameter
      console.log('task: ', parameter);
      console.log('param: ', param);
      console.log(`${filter[param.nome]} in ${param.nome}`);
    })
    if (button?.restRoute?.includes(':id'))
      button.restRoute = button.restRoute.replace(':id', task.id)
    if (button.type == 'GET' && button.restRoute)
      this.api.ClassicGet(button.restRoute).subscribe(data => {
        if (button.successMex)
          this.Toastr.success(button.successMex);
        if (button.close)
          this.close();
        this.updated.emit(data);
        this.Refresh.emit();

      })
    else if (button.type == 'POST' && button.restRoute)
      this.api.ClassicPost(button.restRoute, filter).subscribe(data => {
        if (button.successMex)
          this.Toastr.success(button.successMex);
        if (button.close)
          this.close();
        else (
          this.Refresh.emit()
        )
        this.updated.emit(data);
      })
    else if (button.type == 'GOTO' && button.restRoute) {

      window.open(button.restRoute, '_blank');
      if (button.successMex)
        this.Toastr.success(button.successMex);
      if (button.close)
        this.close();
    }

    else if (FromLista)
      this.api.ClassicPost(`${Structure.restRoute}/get`, { id: task.id }).subscribe(Item => {
        this.ExecuteTask(Item, button, Structure, TaskEdited, FromLista, ShowEditModal);
      })
    else
      this.ExecuteTask(task, button, Structure, TaskEdited, FromLista, ShowEditModal);
  }
  ExecuteTask(Item, button: ActionButton, Structure, TaskEdited, FromLista, ShowEditModal = false) {
    if (button?.type == 'addTask' && button?.taskType) {
      this.addDynamicButton(this.campoUtility.getDynamicTaskSctructure(button.taskType, button.taskFilter), Item, button.concat, button.copy)

      if (button.successMex)
        this.Toastr.success(button.successMex);
      if (button.close)
        this.close();
    }
    if (button.type == 'addTaskRicorrente' && button?.taskType) {
      var cliente = Item['clientiObject'] ? Item['clientiObject'].map(x => x.id) : undefined;
      var tasktype = this.campoUtility.getDynamicTaskSctructure(button.taskType, button.taskFilter);
      var newtask = {
        cliente: cliente ?? Item.cliente,
        clienteObject: Item.clienteObject,
        valutazione: Item.type == "V" ? Item.id : null,
        padre: (this.isTaskType(Item, ['O', 'R',]) || tasktype.type == 'Y') ? Item.id : null,
        tipo: button.taskFilter,
        nome: Item.nome,
        alert_inizio_task: 0,
        alert_fine_task: 0,
      };
      button.concat?.forEach(concatObj => {
        newtask[concatObj.to] = newtask[concatObj.to] ? newtask[concatObj.to] + this.campoUtility.getObj(Item, concatObj.from) : this.campoUtility.getObj(Item, concatObj.from);
      });
      button.copy?.forEach(concatObj => {
        newtask[concatObj.to] = this.campoUtility.getObj(Item, concatObj.from);
      });
      if (!Item.data_fine_prevista) {
        this.Toastr.error("Indicare la data di fine per utilizzare questa funzionalità", "Errore");
        return;
      }
      if (!tasktype?.campi?.some(x => this.campoUtility.Show(x, newtask, true)))
        this.AddPeriodicTask(Item, newtask, tasktype);
      else {
        const editmodal = this.modalService.open(NewsComponent, {
          centered: true,
          backdrop: 'static',
          size: 'xl',
        });
        (<NewsComponent>editmodal.componentInstance).DynamicFields = tasktype;
        (<NewsComponent>editmodal.componentInstance).ExternalAdd = true;
        (<NewsComponent>editmodal.componentInstance).IsModal = true;
        (<NewsComponent>editmodal.componentInstance).Item = newtask;
        (<NewsComponent>editmodal.componentInstance).FromLista = FromLista;
        (<NewsComponent>editmodal.componentInstance).updated.subscribe(data => {
          this.AddPeriodicTask(Item, data, tasktype)
        });
      }
    }
    if (button.type == 'esegui') {
      Item.data_inizio_effettiva = this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm')
      this.save(Item, Structure);
    }
    if (button.type == 'edit' && button.campi) {
      if (ShowEditModal) {
        var structure = Object.assign({}, Structure);
        structure.campi = button.campi;
        structure.sezioni = button.sezioni;
        if (button.otp) {
          this.api.ClassicPost(`${Structure.restRoute}/otp`, { id: Item.id }).subscribe(data => {
            this.Toastr.info(data.message)
          });
        }
        const editmodal = this.modalService.open(NewsComponent, {
          centered: true,
          backdrop: 'static',
          size: 'xl',
        });
        (<NewsComponent>editmodal.componentInstance).DynamicFields = structure;
        (<NewsComponent>editmodal.componentInstance).ExternalAdd = true;
        (<NewsComponent>editmodal.componentInstance).IsModal = true;
        (<NewsComponent>editmodal.componentInstance).HideDelete = true;
        (<NewsComponent>editmodal.componentInstance).FromLista = true;
        (<NewsComponent>editmodal.componentInstance).HideButtons = button.HideButtons;
        (<NewsComponent>editmodal.componentInstance).Item = Item;
        (<NewsComponent>editmodal.componentInstance).updated.subscribe(data => {
          var structure = Object.assign({}, Structure);
          structure.campi = button.campi;
          var tsk = Object.assign(Item, data)
          this.save(tsk, structure, button.close);
          //this.save(data,structure);
        });
      } else {
        var structure = Object.assign({}, Structure);
        structure.campi = button.campi;
        var campiEditati = {};
        button.campi.forEach(element => {
          campiEditati[element.oggetti[0]] = TaskEdited[element.oggetti[0]];
        });
        var tsk = Object.assign(Item, campiEditati);
        this.save(tsk, structure, button.close);
      }

    }
  }

  save(Item, structure, close?: boolean, ExternalAdd = false, OnEnd?: (x) => void,) {
    if (!this.campoUtility.ValidateError(Item, structure.campi)) return;
    Item.partecipanti = Item?.partecipantiObject?.map(x => x.id)
    Item.categorie = Item?.categoria ? [Item?.categoria] : [];
    if (Item['clientiObject'] && Item.type == "N")
      Item['clienti'] = Item['clientiObject'].map(x => { return { id: x.id, letto: false } });
    else if (Item['clientiObject'])
      Item['clienti'] = Item['clientiObject'].map(x => { return x.id });
    Item.periodo = Item['ripetizione']?.repeatEvery;
    Item.tipo_periodo = Item['ripetizione']?.frequency;
    Item.giorno_mese_ordine = Item['ripetizione']?.ordine;
    //Item.giorno_mese_giorno = Item['ripetizione']?.daysOfWeek; !!è un array
    Item.frequenza = Item['ripetizione']?.occurrences;

    if (ExternalAdd) {
      if (OnEnd)
        OnEnd({ Item: Item, close: close ?? false, });
      this.updated.emit(Item);
      this.close();
      return;
    } else if (Item.id)
      this.api.ClassicPost(`${structure.restRoute}/update`, Item).subscribe((data) => this.saved(Item, close, OnEnd));
    else
      this.api.ClassicPost(`${structure.restRoute}/add`, Item).subscribe((data) => this.saved(data, close, OnEnd));
    if (Item.type == 'E' && Item?.tipo == 'LA' && Item.figli?.length > 0 && Item.clientiObject) {
      this.UpdateAttivitas(Item);
    }
  }
  UpdateAttivitas(Item) {
    Item?.figli?.forEach(figlio => {
      this.api.ClassicPost(`${this.campoUtility.getDynamicTaskSctructure(figlio.type, figlio?.tipo ?? null)?.restRoute}/update`, figlio).subscribe()
    })
  }
  saved(Item, close?: boolean, OnEnd?: (x) => void,) {
    //console.log("Error", this.ValidateError(data))

    //this.updated.emit(Item);
    this.Refresh.emit(Item);
    if (close)
      this.close();
    if (OnEnd)
      OnEnd({ Item: Item, close: close ?? false, });
  }
  AddPeriodicTask(Item, data: any, tasktype: any) {
    this.CrudService.CreatePeriodicRelatedTask(Item, data, tasktype, () => {
      this.saved(Item, false)
    })

  }

  isTaskType(Item, tipi: string[]) {
    return tipi?.includes(Item.type);
  }
  generateDateArray(startDate: string, endDate: string, repeatEvery: number, frequency: string, daysOfWeek: any, endCondition: string, endDateCondition: string, occurrences: number): string[] {
    var exError = this.validateRipetizione(startDate, endDate, repeatEvery, frequency, daysOfWeek, endCondition, endDateCondition, occurrences)
    if (exError) {
      this.Toastr.error(exError, 'Errore')
      return [];
    }

    const result: string[] = [];
    let currentDate = new Date(startDate);
    const finalDate = endCondition === 'date' ? new Date(endDateCondition!) : new Date(endDate);
    let count = 0;
    // Helper function to format date as yyyy-MM-dd
    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    while (currentDate <= finalDate && (endCondition !== 'occurrences' || count < occurrences!)) {
      const dayOfWeek = currentDate.getDay();
      const dayMap = {
        0: 'sunday',
        1: 'monday',
        2: 'tuesday',
        3: 'wednesday',
        4: 'thursday',
        5: 'friday',
        6: 'saturday'
      };

      if (frequency !== 'weeks' || daysOfWeek[dayMap[dayOfWeek]]) {
        result.push(formatDate(currentDate));
        count++;
      }

      switch (frequency) {
        case 'days':
          currentDate.setDate(currentDate.getDate() + repeatEvery);
          break;
        case 'weeks':
          currentDate.setDate(currentDate.getDate() + repeatEvery * 7);
          break;
        case 'months':
          currentDate.setMonth(currentDate.getMonth() + repeatEvery);
          break;
        case 'years':
          currentDate.setFullYear(currentDate.getFullYear() + repeatEvery);
          break;
        default:
          throw new Error(`Unsupported frequency: ${frequency}`);
      }
    }

    return result;

  }
  generateDateArrayEveryXDays(startDate: string, intervalDays: number, endDate?: string): string[] {
    const result: string[] = [];
    let currentDate = new Date(startDate);
    var finalDate = new Date(endDate);
    finalDate.setDate(finalDate.getDate() + 1)

    if (isNaN(currentDate.getTime()) || isNaN(finalDate.getTime())) {
      console.error('Date non valide:', startDate, endDate);
      return result;
    }
    if (intervalDays <= 0) {
      console.error('intervalDays deve essere un numero positivo:', intervalDays);
      return result;
    }

    // Helper function to format date as yyyy-MM-dd
    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    // Loop per generare le date
    while (currentDate <= finalDate) {
      result.push(formatDate(currentDate));
      var dummy = new Date(currentDate)
      dummy.setDate(currentDate.getDate() + intervalDays);
      currentDate = dummy;
    }
    console.log('Date da aggiungere', result);
    return result;
  }
  validateRipetizione(
    startDate: string,
    endDate: string,
    repeatEvery: number,
    frequency: string,
    daysOfWeek: any,
    endCondition: string,
    endDateCondition?: string,
    occurrences?: number
  ): string | null {
    const validFrequencies = ['days', 'weeks', 'months', 'years'];
    const validEndConditions = ['never', 'date', 'occurrences'];

    const isValidDate = (dateStr: string): boolean => !isNaN(new Date(dateStr).getTime());

    if (!isValidDate(startDate)) {
      return 'La data di inizio non è valida.';
    }

    if (endCondition === 'date' && (!endDateCondition || !isValidDate(endDateCondition))) {
      return 'La data di fine non è valida.';
    }

    if (endCondition === 'date' && new Date(endDateCondition!) <= new Date(startDate)) {
      return 'La data di fine deve essere successiva alla data di inizio.';
    }

    if (repeatEvery <= 0) {
      return 'L\'intervallo di ripetizione deve essere un numero positivo maggiore di zero.';
    }

    if (!validFrequencies.includes(frequency)) {
      return 'La frequenza non è valida.';
    }

    if (!validEndConditions.includes(endCondition)) {
      return 'La condizione di fine non è valida.';
    }

    if (endCondition === 'occurrences' && (!occurrences || occurrences <= 0)) {
      return 'Il numero di occorrenze deve essere un numero positivo maggiore di zero.';
    }

    if (frequency === 'weeks' && !Object.values(daysOfWeek).some(day => day)) {
      return 'Deve essere selezionato almeno un giorno della settimana per la frequenza settimanale.';
    }

    return null;
  }

  close() {

  }

  Open(task, type, subtype?) {
    var dynamicFields = this.campoUtility.getDynamicTaskSctructure(type, subtype);
    let Newsmodal = this.modalService.open(NewsComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });
    (<NewsComponent>Newsmodal.componentInstance).DynamicFields = dynamicFields;
    (<NewsComponent>Newsmodal.componentInstance).IsModal = true;
    (<NewsComponent>Newsmodal.componentInstance).Item = task ?? new Task();
    (<NewsComponent>Newsmodal.componentInstance).updated.subscribe(data => {
      this.Refresh.emit()
    });
  }
  OpenFromTask(task) {
    this.Open(task, task.type)
  }
}


