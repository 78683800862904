<div class="row">
  <ng-container *ngFor="let campo of Campi">
    <div [ngClass]="campo.prevDivClass"></div>
    <div [ngClass]="campo.class" [ngStyle]="campo.style" *ngIf="campoUtility.Show(campo, Oggetto, true, Type, CampiNascosti ?? [])">
      <ng-container *ngFor="let oggetto of campo.oggetti; let i = index">
        <ng-template
          [ngIf]=" campo.type != 'Crud' && campo.inputType != 'firma' && campo.type != 'CategoriaNav' && campo.type != 'checkValutazione' && campo.type != 'PartecipantiTyped' && campo.type != 'object' && campo.inputType != 'html' && campo.inputType != 'blackhtml' &&  campo.inputType != 'checkbox' && campo.inputType != 'singleDropdown' && campo.inputType != 'multiselectDropdown' &&  campo.inputType != 'textarea' && campo.type != 'ArrayCampiAggiuntivi' && campo.type != 'checkboxArray' && campo.type!= 'table' && campo.inputType != 'file' && campo.type!= 'Partecipanti' && campo.type != 'checkboxArrayMEXC' && campo.type != 'Files' && campo.type != 'image' && campo.inputType != 'ripetizioneTask'">
          <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle" *ngIf="!hideLabel">{{campo.nome | translate}}</label>
          <div class="input-group" style="align-items: center">
            <input [class]="campo.inputClass" [ngStyle]="campo.inputStyle" [type]="campo.inputType"
                   [disabled]="campoUtility.Disable(campo, Padre ?? Oggetto)"
                   [placeholder]="campo.placeholder ?? campo.nome | translate" [(ngModel)]="Oggetto[oggetto]" (ngModelChange)="ChangeHandler(campo.OnChange, $event, campo)"
                   class="form-control"> &nbsp;
            <div class="input-group-append">
              <ng-container *ngFor="let bottone of campo.azioni">
                <a  href="javascript:void(0)" *ngIf="campoUtility.ButtonShow(bottone, Oggetto[oggetto])" [ngbTooltip]="bottone.tooltip"
                    class="link font-16 font-weight-bold" [style.color]="bottone.color"
                    (click)='ChangeHandler(bottone.ExternalOnClick, Oggetto[oggetto], bottone, campo)'>
                  &nbsp;
                  <i  [ngClass]="bottone.icon"></i>
                  &nbsp;
                </a>
              </ng-container>
            </div>
          </div>
          <small class="form-text text-danger"
                 *ngIf="!isModifyDisabled && (campo.required || campoUtility.Required(campo, Oggetto)) && !Oggetto[oggetto]">Attenzione il campo {{campo.nome | translate}} non è compilato!</small>
        </ng-template>
        <ng-template [ngIf]="campo.inputType == 'html'">
          <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle">{{campo.nome | translate}}</label>
          <angular-editor [class]="campo.inputClass" [ngStyle]="campo.inputStyle"
                          [disabled]="campoUtility.Disable(campo, Padre ?? Oggetto)"
                          [placeholder]="campo.placeholder ?? campo.nome | translate" [(ngModel)]="Oggetto[oggetto]" (ngModelChange)="ChangeHandler(campo.OnChange, $event, campo)"
                        [config]="editorConfig"></angular-editor>

        </ng-template>
        <ng-template [ngIf]="campo.inputType == 'blackhtml'">
          <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle">{{campo.nome | translate}}</label>
          <angular-editor [class]="campo.inputClass" [ngStyle]="campo.inputStyle"
                          [disabled]="campoUtility.Disable(campo, Padre ?? Oggetto)"
                          [placeholder]="campo.placeholder ?? campo.nome | translate" [(ngModel)]="Oggetto[oggetto]" (ngModelChange)="ChangeHandler(campo.OnChange, $event, campo)"
                          [config]="blackEditorConfig"></angular-editor>
        </ng-template>
        <ng-template
          [ngIf]=" campo.type != 'Crud' && campo.inputType != 'firma' && campo.type == 'object' && campo.inputType != 'checkbox' && campo.inputType != 'singleDropdown' && campo.inputType != 'multiselectDropdown'  && Oggetto[oggetto] && campo.inputType != 'textarea' && campo.type != 'ArrayCampiAggiuntivi' && campo.type != 'checkboxArray' && campo.type!= 'table' && campo.type!= 'Partecipanti' && campo.type != 'checkboxArrayMEXC' && campo.type != 'image'">
          <label [class]="campo.titleClass"  *ngIf="!hideLabel" [ngStyle]="campo.titleStyle">{{campo.nome | translate}}</label>
          <input [class]="campo.inputClass" [ngStyle]="campo.inputStyle" [type]="campo.inputType"
                 [disabled]="campoUtility.Disable(campo, Padre ?? Oggetto)"
                 [placeholder]="campo.placeholder ?? campo.nome | translate"
                 [(ngModel)]="Oggetto[oggetto][campo.field[i]]" (ngModelChange)="OnChange.emit($event)" class="form-control"> &nbsp;
          <small class="form-text text-danger"
                 *ngIf="!isModifyDisabled && (campo.required || campoUtility.Required(campo, Oggetto)) && !Oggetto[oggetto][campo.field[i]]">Attenzione il campo {{campo.nome | translate}} non è compilato!</small>
        </ng-template>
        <ng-template [ngIf]="campo.type != 'object' && campo.inputType == 'checkbox'">
          <label [class]="campo.titleClass" *ngIf="!hideLabel" [ngStyle]="campo.titleStyle">{{campo.nome | translate}}</label>
          <input [class]="campo.inputClass" [ngStyle]="campo.inputStyle" type="checkbox"
                 [disabled]="!permission.isOperativeRoles(campo.AllowedRoles) || !permission.isntOperativeRoles(campo.prohibitedRoles) || !permission.HasCapacity(campo.allowedCapacity) || campoUtility.Disable(campo, Padre ?? Oggetto)"
                 [placeholder]="campo.placeholder ?? campo.nome | translate" [(ngModel)]="Oggetto[oggetto]" (ngModelChange)="OnChange.emit($event)"
                 class="form-control"> &nbsp;
          <small class="form-text text-danger"
                 *ngIf="!isModifyDisabled && campoUtility.Required(campo, Oggetto) && !Oggetto[oggetto]">Attenzione il campo {{campo.nome | translate}} non è compilato!</small>
        </ng-template>
        <ng-template [ngIf]="campo.type == 'object' && campo.inputType == 'checkbox' && Oggetto[oggetto] ">
          <label [class]="campo.titleClass" *ngIf="!hideLabel" [ngStyle]="campo.titleStyle">{{campo.nome | translate}}</label>
          <input [class]="campo.inputClass" [ngStyle]="campo.inputStyle" type="checkbox"
                 [disabled]="!permission.isOperativeRoles(campo.AllowedRoles) || !permission.isntOperativeRoles(campo.prohibitedRoles) || !permission.HasCapacity(campo.allowedCapacity) || campoUtility.Disable(campo, Padre ?? Oggetto)"
                 [placeholder]="campo.placeholder ?? campo.nome | translate"
                 [(ngModel)]="Oggetto[oggetto][campo.field[i]]" (ngModelChange)="OnChange.emit($event)" class="form-control"> &nbsp;
          <small class="form-text text-danger"
                 *ngIf="!isModifyDisabled && campoUtility.Required(campo, Oggetto) && !Oggetto[oggetto][campo.field[i]]">Attenzione il campo {{campo.nome | translate}} non è compilato!</small>
        </ng-template>

        <ng-container *ngIf="campo.type != 'object'  && campo.inputType == 'ripetizioneTask' ">
          <app-ripetizione-task [(ripetizione)]="Oggetto[oggetto]"></app-ripetizione-task>
        </ng-container>
        <ng-container *ngIf="campo.type == 'object' && campo.inputType == 'ripetizioneTask' && Oggetto[oggetto] ">
          <app-ripetizione-task [(ripetizione)]="Oggetto[oggetto][campo.field[i]]"></app-ripetizione-task>
        </ng-container>

        <ng-template [ngIf]="campo.type != 'object' && campo.inputType == 'textarea'">
          <label *ngIf="!hideLabel" [class]="campo.titleClass" [ngStyle]="campo.titleStyle">{{campo.nome | translate}}</label>
          <textarea [class]="campo.inputClass" [ngStyle]="campo.inputStyle"
                    [disabled]="!permission.isOperativeRoles(campo.AllowedRoles) || !permission.isntOperativeRoles(campo.prohibitedRoles) || !permission.HasCapacity(campo.allowedCapacity)  || campoUtility.Disable(campo, Padre ?? Oggetto)"
                    [(ngModel)]="Oggetto[oggetto]"  (ngModelChange)="OnChange.emit($event)" [placeholder]="campo.placeholder ?? campo.nome | translate"
                    class="form-control"></textarea> &nbsp;
          <small class="form-text text-danger"
                 *ngIf="!isModifyDisabled && campoUtility.Required(campo, Oggetto) && !Oggetto[oggetto]">Attenzione il campo {{campo.nome | translate}} non è compilato!</small>
        </ng-template>
        <ng-template [ngIf]="campo.type == 'object'  && Oggetto[oggetto] && campo.inputType == 'textarea'">
          <label *ngIf="!hideLabel" [class]="campo.titleClass" [ngStyle]="campo.titleStyle">{{campo.nome | translate}}</label>
          <textarea [class]="campo.inputClass" [ngStyle]="campo.inputStyle"
                    [disabled]="!permission.isOperativeRoles(campo.AllowedRoles) || !permission.isntOperativeRoles(campo.prohibitedRoles) || !permission.HasCapacity(campo.allowedCapacity) || campoUtility.Disable(campo, Padre ?? Oggetto)"
                    [(ngModel)]="Oggetto[oggetto][campo.field[i]]"
                    (ngModelChange)="OnChange.emit($event)"
                    [placeholder]="campo.placeholder ?? campo.nome | translate" class="form-control"></textarea> &nbsp;
          <small class="form-text text-danger"
                 *ngIf="!isModifyDisabled && campoUtility.Required(campo, Oggetto) && !Oggetto[oggetto][campo.field[i]]">Attenzione il campo {{campo.nome | translate}} non è compilato!</small>
        </ng-template>


        <ng-template
          [ngIf]="campo.type != 'object' && (campo.inputType == 'singleDropdown' || campo.inputType == 'multiselectDropdown')">
          <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle" *ngIf="!hideLabel">{{campo.nome | translate}}</label>
          <ng-multiselect-dropdown
            *ngIf="campo.internalOutput && ((DropdownSources && DropdownSources[campo.source]) || IsArray(campo.source))"
            [disabled]="campoUtility.Disable(campo, Padre ?? Oggetto)"
            [settings]="campo.settings"
            [placeholder]="campo.placeholder ?? 'Seleziona'"
            [class]="campo.inputClass" [ngStyle]="campo.inputStyle"
            [data]="DropdownSources[campo.source] ?? campo.source"
            [(ngModel)]="Oggetto[campo.internalOutput]"
            (onFilterChange)="FilterHandler(campo, $event)"
            (ngModelChange)="ChangeHandler(campo.OnChange, $event, campo)"></ng-multiselect-dropdown>
          <ng-multiselect-dropdown
            *ngIf="campo.externalOutput && ((DropdownSources && DropdownSources[campo.source]) || IsArray(campo.source))"
            [disabled]="campoUtility.Disable(campo, Padre ?? Oggetto)"
            [required]="campo.required"
            [settings]="campo.settings"
            [placeholder]="campo.placeholder ?? 'Seleziona'"
            [class]="campo.inputClass"
            [ngStyle]="campo.inputStyle"
            [data]="DropdownSources[campo.source] ?? campo.source"
            [(ngModel)]="DropdownSources[campo.externalOutput]"
            (onFilterChange)="FilterHandler(campo, $event)"
            (ngModelChange)="ChangeHandler(campo.OnChange, $event, campo)">
          </ng-multiselect-dropdown>
          <small class="form-text text-danger"
                 *ngIf="!isModifyDisabled && campoUtility.Required(campo, Oggetto) && !DropdownSources[campo.externalOutput] && (!Oggetto[campo.internalOutput] || (!campo.settings.singleOutput && !(Oggetto[campo.internalOutput]?.length >0)))" >Attenzione il campo {{campo.nome | translate}} non è compilato!</small>
        </ng-template>
        <ng-template
          [ngIf]="campo.type == 'object'  && Oggetto[oggetto] && (campo.inputType == 'singleDropdown' || campo.inputType == 'multiselectDropdown')">
          <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle">{{campo.nome | translate}}</label>
          <ng-multiselect-dropdown
            *ngIf="campo.internalOutput && ((DropdownSources && DropdownSources[campo.source]) || IsArray(campo.source) )"
            [disabled]="campoUtility.Disable(campo, Padre ?? Oggetto)" [required]="campo.required" [settings]="campo.settings"
            [placeholder]="campo.placeholder ?? 'Seleziona'"
            [class]="campo.inputClass" [ngStyle]="campo.inputStyle"
            [data]="!IsArray(campo.source) ? DropdownSources[campo.source] : campo.source"
            [(ngModel)]="Oggetto[campo.internalOutput][campo.field[i]]"
            (onFilterChange)="FilterHandler(campo, $event)"
            (ngModelChange)="ChangeHandler(campo.OnChange, $event, campo)"></ng-multiselect-dropdown>
          <ng-multiselect-dropdown
            *ngIf="campo.externalOutput && ((DropdownSources && DropdownSources[campo.source]) || IsArray(campo.source))"
            [disabled]="campoUtility.Disable(campo, Padre ?? Oggetto)" [required]="campo.required" [settings]="campo.settings"
            [placeholder]="campo.placeholder ?? 'Seleziona'"
            [class]="campo.inputClass" [ngStyle]="campo.inputStyle"
            [data]=" !IsArray(campo.source) ? DropdownSources[campo.source] : campo.source"
            [(ngModel)]="DropdownSources[campo.externalOutput][campo.field[i]]"
            (onFilterChange)="FilterHandler(campo, $event)"
            (ngModelChange)="ChangeHandler(campo.OnChange, $event, campo)">
          </ng-multiselect-dropdown>
          <small class="form-text text-danger"
                 *ngIf="!isModifyDisabled && campoUtility.Required(campo, Oggetto) && !DropdownSources[campo.externalOutput] && !Oggetto[campo.internalOutput]">Attenzione il campo {{campo.nome | translate}} non è compilato!</small>
        </ng-template>


        <ng-template [ngIf]="campo.type == 'ArrayCampiAggiuntivi'">
          <app-field-viewer *ngIf="!campo.campiAggiuntivi" [(DropdownSources)]="DropdownSources"
                            [(Campi)]="Oggetto[oggetto]" [(Oggetto)]="Oggetto" [isModifyDisabled]="isModifyDisabled"
                            (OnChange)="OnChange.emit($event)" (OnFilter)="OnFilter.emit($event)"></app-field-viewer>
          <app-field-viewer *ngIf="campo.campiAggiuntivi" [(DropdownSources)]="DropdownSources"
                            [(Campi)]="campo.campiAggiuntivi" [(Oggetto)]="Oggetto"
                            [isModifyDisabled]="isModifyDisabled" (OnChange)="OnChange.emit($event)"
                            (OnFilter)="OnFilter.emit($event)"></app-field-viewer>
        </ng-template>


        <ng-template [ngIf]="campo.type == 'checkboxArray' && Oggetto && Oggetto[oggetto]">
          <app-checkbox-array-viewer [campoAggiuntivo]="campo" [titolo]="campo.nome"  [(Oggetto)]="Oggetto" [HideCampiAgg]="campo['HideCampiAgg']" (OnChange)="OnChange.emit($event)" [(CheckList)]="Oggetto[oggetto]"></app-checkbox-array-viewer>
        </ng-template>
        <ng-template [ngIf]="campo.type == 'checkboxArrayMEXC'">
          <app-checkbox-array-viewer [campoAggiuntivo]="campo" [titolo]="campo.nome" [(Oggetto)]="Oggetto" (OnChange)="OnChange.emit($event)" [MutualmenteEsclusivi]="true" [(CheckList)]="Oggetto[oggetto]"></app-checkbox-array-viewer>
        </ng-template>

        <ng-container *ngIf="campo.inputType == 'file'">
          <div class="col-12">
            <div class="input-group">
              <div class="input-group-prepend">
                <span [class]="campo.titleClass" [ngStyle]="campo.titleStyle" class="input-group-text">{{campo.nome}}</span>
              </div>
              <div class="custom-file">
                <input [class]="campo.inputClass" [ngStyle]="campo.inputStyle" type="file"
                       [disabled]="campoUtility.Disable(campo, Padre ?? Oggetto)"
                       class="custom-file-input" id="allegato" (change)="filechange(campo.OnChange, $event, campo)" placeholder="">
                <label class="custom-file-label" for="allegato">{{Oggetto[(campo?.oggetti[0] ?? '') + 'file']}}</label>
              </div>
            </div>
          </div>
          <div class="col-12">
            <span>{{campo.placeholder}}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="campo.inputType == 'firma'">
            <div style="padding: 20px;" >
              <signature-pad #pad style="display: inline-block; outline-style: groove;
                outline-color: black;
                outline-width: 0.5px;
               "
                [options]="signaturePadOptions"
                (onEndEvent)="drawComplete(campo, pad)"
                ></signature-pad>
                <button (click)="cancellaFirma(pad)"
                  class=" btn btn-danger p-2 waves-effect waves-light">
                  <span class="ml-1 text-white font-weight-bold"> Pulisci </span>
                </button>
            </div>
        </ng-container>

        <ng-container *ngIf=" campo.type == 'image'">
            <div class="el-element-overlay">
              <div class="el-card-item">
                <div class="el-card-avatar el-overlay-1">
                  <img *ngIf="Oggetto[oggetto] || Oggetto[campo.imageToServer]" [src]="Oggetto[campo.imageToServer] ? Oggetto[campo.imageToServer] : GetImageID(Oggetto[oggetto])" (error)="onImageError(Oggetto)" alt="user" />
                  <div class="el-overlay">
                    <ul class="list-style-none el-info">
                      <li class="el-item" *ngIf="GetImageID(Oggetto[oggetto])">
                        <a class="btn default btn-outline image-popup-vertical-fit el-link" [href]="GetImageID(Oggetto[oggetto])">
                          <i class="icon-magnifier"></i>
                        </a>
                      </li>
                      <li class="el-item">
                        <a class="btn default btn-outline el-link" href="javascript:void(0);">
                          <i class="icon-link"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn btn-info waves-effect waves-light">
              <span>Upload Anonther Image</span>
              <input type="file" class="upload" (change)="ImageChange($event, campo.imageToServer, 'image', campo.filename)">
            </div>
        </ng-container>

        <ng-container *ngIf="campo.type == 'checkValutazione'">
          <app-check-list-valutazione  [(Selected)]="Oggetto[oggetto]" [valutazione]="Oggetto"></app-check-list-valutazione>
        </ng-container>

        <ng-template [ngIf]="campo.type == 'table'">
          <div class="d-md-flex align-items-center  py-2" style="background-color: #87858525">
            <span class="d-flex align-items-center  ">
              <span class="font-weight-bold font-14" style="margin-left: 10px">{{campo.nome}} &nbsp;&nbsp;&nbsp;</span>
              <span
                class="badge badge-danger badge-pill px-3 font-weight-bold font-14">{{Oggetto[oggetto]?.length ?? 0}}</span>
              <a href="javascript:void(0)" class="ml-auto"  *ngIf="campo.collapsable" (click)="campo.collapsed = !campo.collapsed"><i class="fas fa-chevron-down text-dark" style="transition: all 0.3s ease-out; transform-origin: top;"  [style.transform]="!campo.collapsed? 'rotate(180deg) translate(0, -50%)' : ''"></i></a>

            </span>
            <div class="ml-auto mt-3 mt-md-0" *ngIf="campo.AddClick || campo.ExternalAddClick">
              <button type="button" (click)="AddClick(campo)" class="btn btn-cyan rounded-pill p-2"><i
                class="fas fa-plus  font-14 mr-1 text-white"></i>
                <span class="ml-1 text-white font-weight-bold">{{'Fields.add' | translate}}</span>
              </button>
            </div>
          </div>
          <div class="table-responsive py-3" *ngIf="campo.collapsed || !campo.collapsable">
            <table class="table bg-white table-hover align-middle mb-0 ">
              <thead>
              <tr>
                <ng-container *ngFor="let headfield of campo.header">
                  <th scope="col" *ngIf="campoUtility.Show(campo, Oggetto, true, Type) && headfield.inputType != 'checkbox'">{{headfield.nome}}</th>
                  <th scope="col" *ngIf="campoUtility.Show(campo, Oggetto, true, Type) && headfield.inputType == 'checkbox'">
                    <a href="javascript:void(0)" class="link" (click)="toggleAll(headfield, Oggetto[oggetto])">{{allSelected(headfield, Oggetto[oggetto]) ? 'Deseleziona tutto' : 'Seleziona tutto'}}</a>
                  </th>
                </ng-container>
                <th *ngIf="campo.azioni && hasAzioni(campo.azioni, Oggetto[oggetto])">{{'Azioni' | translate}}</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let field of Oggetto[oggetto]; let i = index">
                <ng-container *ngFor="let headfield of campo.header">
                  <td *ngIf="campoUtility.Show(campo, Oggetto, true, Type)">
                    <app-field-viewer [Campi]="[headfield]" [hideLabel]="true" [(Type)]="Type" [(Oggetto)]="Oggetto[oggetto][i]"></app-field-viewer>
                  </td>
                </ng-container>
                <td *ngIf="campo.azioni && hasAzioni(campo.azioni, Oggetto[oggetto])">
                  &nbsp;
                  <ng-container *ngFor="let bottone of campo.azioni">
                    <a  href="javascript:void(0)" *ngIf="campoUtility.ButtonShow(bottone, field)" [ngbTooltip]="bottone.tooltip"
                        class="link font-16 font-weight-bold" [style.color]="bottone.color"
                        (click)='ChangeHandler(bottone.ExternalOnClick, field, bottone, campo)'>
                      <i class="fas" [ngClass]="bottone.icon"></i>
                      &nbsp;
                    </a>
                  </ng-container>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </ng-template>
        <ng-template [ngIf]="campo.type == 'Partecipanti' && Oggetto && Oggetto[oggetto]">
          <app-partecipanti-list [class]="campo.inputClass" [ngStyle]="campo.inputStyle" [titolo]="campo.nome" type="file" [(List)]="Oggetto[oggetto]" [hasAddButton]="true" [Padre]="Oggetto" ></app-partecipanti-list>
        </ng-template>
        <ng-template [ngIf]="campo.type == 'PartecipantiTyped' && Oggetto && Oggetto[oggetto]">
          <app-partecipanti-list [class]="campo.inputClass" [ngStyle]="campo.inputStyle" [titolo]="campo.nome" type="file" [Types]="campo.source"  [Typed]="true" [(List)]="Oggetto[oggetto]" [hasAddButton]="true" [Padre]="Oggetto" ></app-partecipanti-list>
        </ng-template>

        <ng-template [ngIf]="campo.type == 'CategoriaNav' && ((DropdownSources && DropdownSources[campo.source]) || IsArray(campo.source))">
          <app-categorie-nav-select [nameCategoria1]="'Categoria'" [nameCategoria2]="'Area Riabilitativa'" [nameCategoria3]="campo.nome" [(cat_id)]="Oggetto[oggetto]" [categorie]="DropdownSources[campo.source] ?? campo.source"></app-categorie-nav-select>
        </ng-template>
        <small class="form-text text-warning"
               *ngIf="!isModifyDisabled && campoUtility.Warning(campo,Oggetto)">{{campoUtility.WarningText(campo,Oggetto ) | translate}}</small>
      </ng-container>
      <ng-container *ngIf="campo.type == 'Crud'">
        <app-dynamic-list [CrudId]="campo.inputType" [title]="campo.nome ?? ''"  [filtro]="CreateParams(campo.filtro, Oggetto)" [ConcatParams]="CreateParams(campo.CrudSetting['ConcatParams'] ?? {}, Oggetto)"></app-dynamic-list>
      </ng-container>

    </div>
    <div [ngClass]="campo.class" [ngStyle]="campo.style"
         *ngIf=" campoUtility.Show(campo, Oggetto, false, Type, CampiNascosti ?? [])">
      <ng-container *ngFor="let oggetto of campo.oggetti; let i = index">
        <ng-template
          [ngIf]="campo.inputType != 'checkbox' && campo.inputType != 'html' && campo.inputType != 'singleDropdown' && campo.inputType != 'multiselectDropdown' && campo.type != 'ArrayCampiAggiuntivi' && campo.type != 'checkboxArray'&& campo.type != 'Partecipanti' && campo.type != 'checkboxArrayMEXC' && campo.type != 'Files'">
          <label *ngIf="i == 0 && !hideLabel" [class]="campo.titleClass"
                 [ngStyle]="campo.titleStyle">{{campo.nome | translate}}</label>
          <span *ngIf="campo.inputType != 'date' && campo.inputType != 'datetime-local' && campo.inputType != 'euro'"
                [class]="campo.inputClass"
                [ngStyle]="campo.inputStyle">{{campo.prefix ?? ''}}{{getObject(campo, i) }}{{campo.suffix ?? ''}}</span>
          <span *ngIf="campo.inputType == 'date'" [class]="campo.inputClass"
                [ngStyle]="campo.inputStyle">{{campo.prefix ?? ''}}{{getObject(campo, i) | date: 'dd/MM/yyyy'}}{{campo.suffix ?? ''}}</span>
          <span *ngIf="campo.inputType == 'datetime-local'" [class]="campo.inputClass"
                [ngStyle]="campo.inputStyle">{{campo.prefix ?? ''}}{{getObject(campo, i) | date: 'dd/MM/yyyy HH:mm'}}{{campo.suffix ?? ''}}</span>
          <span *ngIf="campo.inputType == 'euro'" [class]="campo.inputClass"
                [ngStyle]="campo.inputStyle">{{campo.prefix ?? ''}}{{getObject(campo, i) | number: '2.2-2'}}&nbsp;€{{campo.suffix ?? ''}}</span>
          <ng-template [ngIf]="campo.type == 'replace' ">
            {{campoUtility.Replace(campo,Oggetto, oggetto)}} &nbsp;
          </ng-template>
          <button type="submit" style="margin-left: 5px" *ngIf="campo.OnChange?.length > 0" (click)="ChangeHandler(campo.OnChange, $event, campo)" class=" btn btn-info p-2 ">
            <span class="ml-1 text-white font-weight-bold"> Aggiorna </span>
          </button>
        </ng-template>
        <ng-template [ngIf]="campo.inputType == 'html'">
          <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle">{{campo.nome | translate}}</label>
          <angular-editor [class]="campo.inputClass" [ngStyle]="campo.inputStyle"
                          [disabled]="campoUtility.Disable(campo, Padre ?? Oggetto)"
                          [placeholder]="campo.placeholder ?? campo.nome | translate" [(ngModel)]="Oggetto[oggetto]" (ngModelChange)="ChangeHandler(campo.OnChange, $event, campo)"
                          [config]="readonlyEditorConfig"></angular-editor>
        </ng-template>
        <ng-template [ngIf]="campo.inputType == 'blackhtml'">
          <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle">{{campo.nome | translate}}</label>
          <angular-editor [class]="campo.inputClass" [ngStyle]="campo.inputStyle"
                          [disabled]="campoUtility.Disable(campo, Padre ?? Oggetto)"
                          [placeholder]="campo.placeholder ?? campo.nome | translate" [(ngModel)]="Oggetto[oggetto]" (ngModelChange)="ChangeHandler(campo.OnChange, $event, campo)"
                          [config]="readonlyEditorConfig"></angular-editor>
        </ng-template>
        <ng-template
          [ngIf]="campo.type != 'object' && (campo.inputType == 'singleDropdown' || campo.inputType == 'multiselectDropdown')">
          <span  *ngIf="campo.internalOutput && IsArray(Oggetto[campo.internalOutput])"  [class]="campo.inputClass" [ngStyle]="campo.inputStyle">
            <ng-template *ngFor="let val of Oggetto[campo.internalOutput]">
               {{campo.prefix ?? ''}}{{val[campo.settings['textField']] ?? val['label']}}{{campo.suffix ?? ''}}
            </ng-template>

          </span>
          <span  *ngIf="campo.internalOutput && Oggetto[campo.internalOutput] && !IsArray(Oggetto[campo.internalOutput])" [class]="campo.inputClass"
                 [ngStyle]="campo.inputStyle">{{campo.prefix ?? ''}}{{Oggetto[campo.internalOutput][campo.settings['textField']] ?? Oggetto[campo.internalOutput]['label']}}{{campo.suffix ?? ''}}</span>
          <span  *ngIf="campo.externalOutput &&  DropdownSources[campo.externalOutput]" [class]="campo.inputClass"
                 [ngStyle]="campo.inputStyle">{{campo.prefix ?? ''}}{{DropdownSources[campo.externalOutput][campo.settings.textField]}}{{campo.suffix ?? ''}}</span>
        </ng-template>
        <ng-template
          [ngIf]="campo.type == 'object'  && Oggetto[oggetto] && (campo.inputType == 'singleDropdown' || campo.inputType == 'multiselectDropdown')">
          <span  *ngIf="campo.externalOutput && Oggetto[campo.externalOutput]" [class]="campo.inputClass"
                 [ngStyle]="campo.inputStyle">{{campo.prefix ?? ''}}{{Oggetto[campo.externalOutput][campo.field[i]][campo.settings.textField]}}{{campo.suffix ?? ''}}</span>
          <span  *ngIf="campo.internalOutput && Oggetto[campo.internalOutput]" [class]="campo.inputClass"
                 [ngStyle]="campo.inputStyle">{{campo.prefix ?? ''}}{{Oggetto[campo.internalOutput][campo.field[i]][campo.settings.textField]}}{{campo.suffix ?? ''}}</span>
        </ng-template>
        <ng-template [ngIf]="campo.inputType == 'checkbox'">
          <label [class]="campo.titleClass" [ngStyle]="campo.titleStyle" *ngIf=" !hideLabel">{{campo.nome | translate}}</label>
          <span [class.text-danger]="!getObject(campo,i)" [class.text-success]="getObject(campo,i)">
            {{campo.prefix ?? ''}}
            <i [class.far]="!getObject(campo,i)" [class.fa-times-circle]="!getObject(campo,i)"
               [class.fas]="getObject(campo,i)" [class.fa-check-circle]="getObject(campo,i)"></i>
            {{campo.suffix ?? ''}}
          </span>
        </ng-template>
        <ng-template [ngIf]="campo.type == 'ArrayCampiAggiuntivi'">
          <app-field-viewer *ngIf="!campo.campiAggiuntivi" [(DropdownSources)]="DropdownSources"
                            [(Campi)]="Oggetto[oggetto]" [(Oggetto)]="Oggetto" [isModifyDisabled]="isModifyDisabled"
                            (OnChange)="OnChange.emit($event)" (OnFilter)="OnFilter.emit($event)"></app-field-viewer>
          <app-field-viewer *ngIf="campo.campiAggiuntivi" [(DropdownSources)]="DropdownSources"
                            [(Campi)]="campo.campiAggiuntivi" [(Oggetto)]="Oggetto"
                            [isModifyDisabled]="isModifyDisabled" (OnChange)="OnChange.emit($event)"
                            (OnFilter)="OnFilter.emit($event)"></app-field-viewer>
        </ng-template>
        <ng-template [ngIf]="campo.type == 'checkboxArrayMEXC'">
          <app-checkbox-array-viewer  [campoAggiuntivo]="campo" [(Oggetto)]="Oggetto" (OnChange)="OnChange.emit($event)" [MutualmenteEsclusivi]="true" [(CheckList)]="Oggetto[oggetto]"></app-checkbox-array-viewer>
        </ng-template>
        <ng-template [ngIf]="campo.type == 'checkboxArray'">
          <app-checkbox-array-viewer [campoAggiuntivo]="campo" [(Oggetto)]="Oggetto" (OnChange)="OnChange.emit($event)" [(CheckList)]="Oggetto[oggetto]"></app-checkbox-array-viewer>
        </ng-template>
        <ng-template [ngIf]="campo.type == 'Partecipanti'">
          <app-partecipanti-list [class]="campo.inputClass" [ngStyle]="campo.inputStyle" [titolo]="campo.nome" type="file" [(List)]="Oggetto[oggetto]" [hasAddButton]="false" [ModificheAbilitate]="false" [Padre]="Oggetto" ></app-partecipanti-list>
        </ng-template>
      </ng-container>
    </div>
    <div [ngClass]="campo.class" [ngStyle]="campo.style"
         *ngIf="campo.type == 'Files' && Oggetto?.id && !(campo?.oggetti?.length>0) && campoUtility.Show(campo, Oggetto, true, Type)">
      <app-file-uploader [id]="Oggetto.id" [(listafile)]="Oggetto.files" [url]="campo.url" [name]="campo.nome"
                         (onChange)="ChangeHandler(campo.OnChange, $event, campo)"
                         (onFileSaved)="ChangeHandler(campo.OnChange, $event, campo)"
                         [DropdownSources]="Oggetto"
                         [GestioneFile] = "campo.GestioneFile ?? ((!campoUtility.Disable(campo, Padre ?? Oggetto) ?? true) || Oggetto['bloccato'])  "
                         [campiAggiuntivi] = "campo.campiAggiuntivi"
                         [attr] = "campo.attr"
                         (allowedFilesChange)="ChangeHandler('allowedFiles', $event, campo)"
                         (listafileChange)="ChangeHandler('listafileChange', $event, campo)"></app-file-uploader>
    </div>
    <div [ngClass]="campo.class" [ngStyle]="campo.style"
         *ngIf="campo.type == 'Files' && Oggetto?.id && campo?.oggetti?.length > 0 && Oggetto[campo.oggetti[0]] && (campo.visibleifProhibited || (permission.isOperativeRoles(campo.AllowedRoles) && permission.isntOperativeRoles(campo.prohibitedRoles) && permission.HasCapacity(campo.allowedCapacity)))">
      <app-file-uploader [id]="Oggetto.id" [(listafile)]="Oggetto[campo.oggetti[0]].files" [url]="campo.url" [name]="campo.nome"
                         (onChange)="ChangeHandler(campo.OnChange, $event, campo)"
                         (onFileSaved)="ChangeHandler(campo.OnChange, $event, campo)"
                         [DropdownSources]="Oggetto"
                         [GestioneFile] = "campo.GestioneFile ?? !campoUtility.Disable(campo, Padre ?? Oggetto) ?? true "
                         [campiAggiuntivi] = "campo.campiAggiuntivi"
                         [attr] = "campo.attr"
                         (allowedFilesChange)="ChangeHandler('allowedFiles', $event, campo)"
                         (listafileChange)="ChangeHandler('listafileChange', $event, campo)"></app-file-uploader>
    </div>

    <div [ngClass]="campo.nextDivClass"></div>
  </ng-container>
</div>
