import { Component, Input, OnInit } from "@angular/core";
import { ApiPathsService } from "src/app/api-paths.service";
import { DynamicCrudService, OggettoConfig } from "../_services/dynamic-crud.service";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { CampoUtility } from "../models/campo-aggiuntivo";
import { Permission } from "src/app/Login/_guards/Permission";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DynamicEditComponent } from "../dynamic-edit/dynamic-edit.component";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-dynamic-list',
    templateUrl: './dynamic-list.component.html'
})
export class DynamicListComponent implements OnInit {
    CrudSettings: OggettoConfig = undefined;
    @Input() CrudId: string ;
    environment = environment;
    @Input() openFilter: boolean = false;
    List: any[] = [];
    @Input() title: string = 'List';
    @Input() FilterViewType: 'normal' | 'sidebar' = 'normal';
    @Input() filtro: any = {}
    @Input() RowClickEnabled: boolean = true;
    @Input() ConcatParams: any = {};
    constructor(
        private crudService: DynamicCrudService,
        private activeRoute: ActivatedRoute,
        public campoUtility: CampoUtility,
        private permission: Permission,
        private modalService: NgbModal,
        private titleService: Title
    ){}
   
    ngOnInit() {
      if(!this.CrudId)
        this.activeRoute.params.subscribe(
            (params: {type: string}) => {
              this.CrudSettings = this.crudService.GetCrudSettings(params.type);
              this.Refresh();
            }
        );
        else {
        this.CrudSettings = this.crudService.GetCrudSettings(this.CrudId);
        this.Refresh();}
    }
    Refresh(){
      if(!this.CrudId)
        this.updateTitle();
      this.crudService.GetList(this.CrudSettings, this.filtro).subscribe(response=>{
          this.List = this.CrudSettings.ListResponseHasData ? response.data: response;
      })
    }
    hasListActions(){
        return this.CrudSettings && this.CrudSettings['AzioniLista']?.filter(x=>!x.single)?.length > 0;
    }
    MassiveButtonClick(button){
      this.crudService.MultipleCustomButtonClick(this.List.filter(x=>x['selected']),button, true,this.CrudSettings, (end)=>this.Refresh(), (refresh)=>{}, (close)=>{})
    }
    ButtonClick(item, button){
      this.crudService.CustomButtonClick(item, button, true, this.CrudSettings, (end)=>this.Refresh(), (refresh)=>{}, (close)=>{})
    }
    dynamicHeader(){
        return this.CrudSettings?.Headers;
    }
    IsArray(val){
        return val && Array.isArray(val);
    } 
    headShow(campo): boolean {
          return true;
    }
    allSelected(headerField: any, data: any[]){
        return data.every(x=> x[headerField]);
      }
    
      toggleAll(headerField: any, data: any[]){
        var oldValue = Object.assign({}, {val: this.allSelected(headerField, data)})
        data.forEach((x)=> x[headerField] = !oldValue.val)
      }
      RowClick(Item){
        if(!this.RowClickEnabled) return;
        this.OpenEditModal(Item);
      }
      EditButton(Item){
        this.OpenEditModal(Item);
      }
      HasAddButton(){
        return this.CrudSettings?.HasAddList && 
            this.campoUtility.CheckValue(this.CrudSettings?.HasAddList, Object.assign(this.CrudSettings.ListDefaultFilter, this.filtro)) &&
            this.permission.HasCapacity(this.CrudSettings.Capacita);
      }
      AddClick(){
       this.OpenEditModal()
      }
      OpenEdit(Item?: Object){
        if(!this.CrudSettings.EditView)
            this.OpenEditModal(Item)
        else
            switch(this.CrudSettings.EditView){
                case 'Modal':
                    this.OpenEditModal(Item);
                    break;
                case 'Page':
                    this.OpenEditPage(Item);
                    break;
            }
        }
      OpenEditModal(Item?: Object){
        const EditModal = this.modalService.open(DynamicEditComponent, {
            centered:true,
            backdrop: 'static',
            size: 'xl'
        });
        (<DynamicEditComponent>EditModal.componentInstance).CrudSettings = this.CrudSettings;
        (<DynamicEditComponent>EditModal.componentInstance).Item = Item ?? Object.assign( this.CrudSettings.AddDefaultObject ?? {}, this.ConcatParams);
        (<DynamicEditComponent>EditModal.componentInstance).updated.subscribe(data => {
            this.Refresh();
        });
      }
      OpenEditPage(Item?: Object){
       
      }
      private updateTitle() {
        this.title = this.CrudSettings.listTitle ?? 'Lista'
        this.titleService.setTitle(this.title);
      }
      Tooltip(item) {
        return ''
      }
     
}





