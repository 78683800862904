
/*
 * .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .
 *    .       .       .       .       .       .       .       .       .       .       .       .
 *      . :SXt. .  :%X%. . ..%S%: . .    . .    .  .    .  .    .  .    .  .    .  .    .  .    .  . .
 *  .   .;8XSXt8 .S8@SX8@  888SS8t.    ..8  %t    .  .      .       .       .   ;X  X:      .
 *    . @%@:8;S8 t8%t8 X8.:88%8.X8X     ;8;:S; .       .  .   . ;;.   .  .    . t8.:X:.t..    .  . .  .
 *   .  8XXS tS8;X88S.SX8::8X; 8X8@ .  .:X;     . .  .   .      t@     .   .   .88    t;8   .   .
 *      8S88:@8@8888S X8888888.8X8@   . @ . . .  ...   .   .. . ;8  .    .   . .8S .  %8: .   .    . .
 *   . .8X88. ;8S%8;.  .8;:8%..8@8@   :S88.S@ ;t@::S S.  @;XttSS88;;;8;;   . %t:@8%:t X8; 8@% :; .
 *      8@@S  .  ...   .. .:. .8X8@ .   S;.:  X8    ;8X t8S     t8   .@;  .88; .8%. . @8.  :8 .8   . .
 *   . .8@@8;  .     .  .     .8X8@    .tX;  ;88.    X@ t8t     t8 .  88S t8@  :8S    @@%   t88.
 *      @@88:     .      .  . .8@8@  . .X .  :8: .  .@S t8@     S@.  . 88%8:.  .8S .  88:  X8%8S.  .  .
 *   .  8X88. .  .   . .   .  .8X8@     X : ..88t%;S@8X %8%  . .:8.     %88t  .:8S   .X8% S8S:X88:
 *     .8XXS    .   .         .8X8@ . ..88.    tXX SS%  :8t     @8. .  .888.   .@S .  tX: 88.. SS:  .
 *   .  8@88:.    .     . .  . 8X8@    .:. .    .: .  . ..  . .  .     S8%. .  .     .    ..       .  .
 *     .@@88:  .     .         8X8@    .     . .   .      .     .  .   SX    .   .      .      .
 *   .  8XX8;    .    .  .  . .8X8X .   . .      .   .  .    .       .88X. .      . . .    .  .  .  .
 *      ;. %  .    .       .  .;:;:.  .     .  .           .   . .  . :;      . .        .         .  .
 *   . . .  .   .   .  .             .   .    .   . . .  .               .  .      .  .    . .  .
 *        .       .      .  . .  .     .   .    .       .   . .  . .  .    .   .        .        .  .
 *   .  .    . .     .    .     .  .     .   .     .  .   .            .  .      . . .    .  . .     .
 *
 * Copyright FortyFix(c) 2023.
 * Title: edit-persona.component.ts;
 * Path: /Volumes/Dati 1/SbSW_SoftwareBySystem_Works/KI_KlimaItalia/GesKI_sbs_gestionale_Klimautalia_front_angular/src/app/Admin/edit-persona/edit-persona.component.ts;
 * Description: ;
 * Author: giovannibattistadellaporta;
 * Created at: 06/09/23, 11:17;
 * Updated at: 01/08/23, 11:48;
 *
 *
 */

import { FileStoreService } from './../../shared/_services/file-store.service';
import { Subscription } from 'rxjs';
import { PersonaleStoreService } from './../_service/personale-store.service';
import {Component, OnInit, Input, Output, EventEmitter, AfterViewInit} from '@angular/core';
import { Persona } from '../models/persona';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {Paginazione} from "../../shared/models/paginazione";
import {ApiRestService} from "../_service/api-rest.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { environment } from 'src/environments/environment';
import {Permission} from "../../Login/_guards/Permission";
import {ApiPathsService} from "../../api-paths.service";
import {HttpHeaders} from "@angular/common/http";
import {Azienda} from "../models/Azienda";
import { DynamicCrudService } from 'src/app/shared/_services/dynamic-crud.service';
@Component({
  selector: 'app-edit-persona',
  templateUrl: './edit-persona.component.html'
})
export class EditPersonaComponent implements OnInit {
  subscriptions: Subscription = new Subscription();
  selectedUser: Persona = new Persona();
  showaddform: boolean;
  @Input() IsModal: boolean = false;
  @Input() IDRuolo: string = undefined;
  @Output() added = new EventEmitter<Persona>();
  editable: boolean;
  environment = environment;
  oldPerson: any;
  ButtonDisabled: boolean;
  constructor(
    private personaleStore: PersonaleStoreService,
    private filestore: FileStoreService,
    private location: Location,
    private apiRestService: ApiRestService,
    private modalService: NgbModal,
    public permission: Permission,
    private apiPaths: ApiPathsService,
    private crudService: DynamicCrudService
  ) {}

  ngOnInit() {
    this.crudService.InitObjects(this.selectedUser, this.environment.Personale['initObj']);
    this.subscriptions.add(this.personaleStore.$showAddForm.subscribe((items) => this.showaddform = items));
    this.subscriptions.add(this.personaleStore.$editable.subscribe((items) => this.editable = items));
    this.subscriptions.add(this.personaleStore.$selectedUser.subscribe((item) => {
      if(item) {
        this.selectedUser = Object.assign({}, item);
        this.crudService.InitObjects(this.selectedUser, this.environment.Personale['initObj']);
        this.oldPerson = {username: item?.username, ruoli: item?.ruoliObject?.map(x=> x.id)};
        this.filestore.SetListaFile(item.files);
      }
    }));
  }
  cancelClick() {
    if(this.IsModal)
      this.modalService.dismissAll();
    else
      this.location.back();
  }
  CustomButtonClick(val: {Button: any, Item: any }){
    this.crudService.CustomButtonClick(this.selectedUser, val.Button, false, {Id: null}, (x)=>{this.saved(x, val.Button.close);}, (x)=>{}, (x)=>{} );

  }
  saved(item, close: boolean){
    this.personaleStore.updateSelectedUser({
      id: this.selectedUser?.id ?? item.id,
      cliente: 0,
      principale: false
    });
  }
  saveClick(data: Persona) {
    this.ButtonDisabled = true;
    var canCreateRmaUser = this.CanCreateRmaUser(data);
    if (data.id){
      this.apiRestService.updateUser(data).subscribe((result) => {
        if(canCreateRmaUser)
          this.CreateRmaUser(data);
        if(data.close){
          this.personaleStore.getListaPersonale(new Paginazione(1,1000));
          this.cancelClick();
        }
        else
          this.personaleStore.updateSelectedUser(data);
        this.ButtonDisabled = false;
      },()=>{this.ButtonDisabled = false;});
    } else {
      this.apiRestService.addNewUser(data).subscribe((val) => {
        if(canCreateRmaUser)
          this.CreateRmaUser(data);
        this.added.emit(val);
        if(data.close){
          this.personaleStore.getListaPersonale(new Paginazione(1,1000));
          this.cancelClick();
        }
        else
          this.personaleStore.updateSelectedUser(val);
        this.ButtonDisabled = false;
      },()=>{this.ButtonDisabled = false;});
    }

  }

  CanCreateRmaUser(data: Persona): boolean {
    console.log("Old person: ", this.oldPerson);
    const hasNewRole: boolean =
        !this.oldPerson?.ruoli?.includes("AG") &&
        data?.ruoli?.includes("AG") &&
        data?.username?.length > 0 &&
        data?.email?.length > 0;

    const hasNewCredentials: boolean =
        !(this.oldPerson?.username?.length > 0) &&
        data?.username?.length > 0 &&
        data?.password?.length > 0 &&
        data?.email?.length > 0 &&
        data?.ruoli?.includes("AG");
    return hasNewRole || hasNewCredentials;
  }

  CreateRmaUser(persona: Persona) {
    if (!environment.Modules['Rma'] || !environment.RmaAddOn || !environment.RmaAddOn['userPiattaformaAssistenza']) {
      return;
    }
    var json = window.atob(environment.RmaAddOn['userPiattaformaAssistenza']);
    console.log(json);
    const { username, password } = JSON.parse(json);
    this.apiPaths.ClassicPost('/auth/login', { username, password }, null, environment.RmaAddOn['linkPiattaformaAssistenza'] + '/api')
        .subscribe(data => {
          let clienteRma: Azienda = {
            id: null,
            nominativo: persona.nome + ' ' + persona.cognome,
            username: persona.username,
            password: persona.password,
            ConfermaPassword: persona.ConfermaPassword,
            emailAccount: persona.email,
            email: persona.email,
            ruoli: ["C"]
          };
          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${data.access_token ? data.access_token : data.token}`,
          });
          const addClienteUrl = '/anagrafica/clienti/add';
          this.apiPaths.ClassicPost(addClienteUrl, clienteRma, { headers }, environment.RmaAddOn['linkPiattaformaAssistenza'] + '/api')
              .subscribe(cliente => {
                console.log("Utente Creato: ", cliente);
              });
        });
  }

  deleteClick() {
    this.personaleStore.deleteUser(this.selectedUser);
    this.cancelClick();
  }
  onFileChanged() {
    this.personaleStore.updateSelectedUser(this.selectedUser);
  }
  get filtriAccessibili() {
    return environment.filtrife.filter( x => (!x?.capacita || this.permission.HasCapacity(x.capacita)) && (!x?.module || environment.Modules[x.module]));
  }
}
