<div class="modal-header"  *ngIf="IsModal" >
  <h5 class="modal-title" id="editUserLabel">Task</h5>
  <button type="button" class="close" (click)="cancelClick()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="card" *ngIf="environment['Personale'] && environment['Personale']['Tabs'] && selectedUser">
  <app-dynamic-tabs 
  [(Padre)]="selectedUser"
    [TaskSettings]="{persona: selectedUser?.id}"
    [CrudSetting]="{ConcatParams: {persona_id: selectedUser?.id}, filtro: {persona_id: selectedUser?.id}}"
    [Tabs]="environment['Personale']['Tabs']"
    (CustomButtonClick)="CustomButtonClick($event)"
    [AvailableTemplates]="[
      {id: 'Edit', template: Edit},
      {id: 'Files', template: Files}
    ]"></app-dynamic-tabs>
  
</div>

<div class="card" *ngIf="!environment['Personale'] || !environment['Personale']['Tabs']">
   <ul ngbNav #nav="ngbNav" [activeId]="'info'" class="nav-tabs">
    <li ngbNavItem="info" >
      <a ngbNavLink> {{selectedUser?.cognome != null && selectedUser?.cognome != ""  ? selectedUser?.cognome  : 'Anagrafica'}} </a>
      <ng-template ngbNavContent >
          <ng-container  *ngTemplateOutlet="Edit"></ng-container>
      </ng-template>
    </li>
    <li [ngbNavItem]="'attività'" *ngIf="selectedUser?.id && (environment['Personale']['Tasks'])">
      <a ngbNavLink> {{environment.TaskAddon['listTitle'] ?? 'Tasks'}} </a>
      <ng-template ngbNavContent>
        <app-list  [hideFiltriFe]="false" [persona]="selectedUser.id"></app-list>
      </ng-template>
    </li>
    <li ngbNavItem="Corsi" *ngIf="!showaddform && selectedUser && (environment['Personale']['Corsi']) && permission.isPermitted('menu-servizi')">
      <a ngbNavLink> {{'Corsi'|translate}} </a>
      <ng-template ngbNavContent>
        <app-edizioni [ClienteId]="selectedUser.id" [hasFilter]="false" ></app-edizioni>
      </ng-template>
    </li>
    <li ngbNavItem="Files" *ngIf="!showaddform && selectedUser?.id">
      <a ngbNavLink> {{'cliente.sezioni.files'|translate}} </a>
      <ng-template ngbNavContent>
       <ng-container  *ngTemplateOutlet="Files"></ng-container>
      </ng-template>
    </li>

  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div> 
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
</div>

<ng-template #Edit >
  <app-dettaglio-persona [(IDRuolo)]="IDRuolo"  (onDeleteClick)="deleteClick()" [(ButtonDisabled)]="ButtonDisabled" (onSaveClick)="saveClick($event)" (onCancelClick)="cancelClick()"></app-dettaglio-persona>
</ng-template>
<ng-template #Files>
  <app-file-uploader [url]="'/anagrafica/persone/file'" [id]="selectedUser.id" (onChange)="onFileChanged()"></app-file-uploader>
</ng-template>



