import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { ApiPathsService } from "src/app/api-paths.service";
import { environment } from "src/environments/environment";
import { CampoUtility, CheckObject } from "../models/campo-aggiuntivo";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DynamicEditComponent } from "../dynamic-edit/dynamic-edit.component";
import { NewsComponent } from "src/app/task/news/news.component";

@Injectable({
  providedIn: 'root'
})
export class DynamicCrudService {
  environment = environment;
  constructor(
    private api: ApiPathsService,
    private datepipe: DatePipe,
    private campoUtility: CampoUtility,
    private Toastr: ToastrService,
    private modalService: NgbModal
  ) { }
  getparam(oggetto, param) {
    return oggetto ? (Array.isArray(oggetto[param]) ? (oggetto[param]?.length > 0 ? oggetto[param][0] : null) : oggetto[param]) : null;
  }
  InitObject(Item, DynamicFields) {
    DynamicFields?.initObj?.forEach(x => {
      if (!x.campo) {
        console.error("è stato definito un initObj senza definire il campo...");
        return
      }
      if (!x.value && !x.tipo) {
        console.error("è stato definito un initObj senza definire il tipo o il valore");
        return
      }
      if (x.value && x.force == true)
        Item[x.campo] = x.value;
      else if (x.value)
        Item[x.campo] = Item[x.campo] ?? x.value;
      else if (x.tipo == 'object')
        Item[x.campo] = Item[x.campo] ?? {};
      else if (x.tipo == 'date')
        Item[x.campo] = Item[x.campo] ?? this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm');
      else if (x.tipo == 'array')
        Item[x.campo] = Item[x.campo] ?? [];
    })
  }
  InitObjects(Item, initObj) {
    initObj?.forEach(x => {
      if (!x.campo) {
        console.error("è stato definito un initObj senza definire il campo...");
        return
      }
      if (!x.value && !x.tipo) {
        console.error("è stato definito un initObj senza definire il tipo o il valore");
        return
      }
      if (x.value && x.force == true)
        Item[x.campo] = x.value;
      else if (x.value)
        Item[x.campo] = Item[x.campo] ?? x.value;
      else if (x.tipo == 'object') {
        var obj = !Item[x.campo] ? undefined : (typeof Item[x.campo] === 'object' || !x.overrideDifferentType ? Item[x.campo] : undefined);
        Item[x.campo] = obj ?? {};
      }
      else if (x.tipo == 'date')
        Item[x.campo] = Item[x.campo] ?? this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm');
      else if (x.tipo == 'array') {
        var obj = Array.isArray(Item[x.campo]) || !x.overrideDifferentType ? Item[x.campo] : undefined;
        Item[x.campo] = obj ?? [];
      }
    })
  }
  GetCrudSettings(type: string) {
    if (!(this.environment['Cruds']?.length >= 0)) return null;
    return this.environment['Cruds']?.find(crud => crud.Id == type);
  }
  Get(route: string, crudSettings: any) {
    var url = `${crudSettings.RestRoute}${route}`;
    return this.api.ClassicGet(url)
  }
  Post(route: string, crudSettings: any, param: any) {
    return this.api.ClassicPost(`${crudSettings.RestRoute}${route}`, param)
  }
  GetByTipo(route: string, type: string) {
    var crudSettings = this.GetCrudSettings(type)
    return this.api.ClassicGet(`${crudSettings.RestRoute}${route}`)
  }
  PostByTipo(route: string, type: string, param: any) {
    var crudSettings = this.GetCrudSettings(type)
    return this.Post(`${route}`, crudSettings, param)
  }
  GetList(crudSettings: any, filter?: any) {
    var filter = Object.assign({ page: 0, size: 10 }, crudSettings.ListDefaultFilter ?? {}, filter);
    return this.Post('/list', crudSettings, filter);
  }
  GetItem(crudSettings: any, filter?: any) {
    var filter = Object.assign({}, filter);
    return this.Post('/get', crudSettings, filter);
  }
  Save(Item, crudSettings: OggettoConfig, close?: boolean, ExternalAdd = false, OnEnd?: (x) => void,) {
    if (!this.campoUtility.ValidateError(Item, crudSettings.sezioni ?? crudSettings.campi)) return;
    if (crudSettings.OnSaveMap)
      Item = crudSettings.OnSaveMap(Item);
    if (ExternalAdd && !OnEnd) return;
    if (ExternalAdd && OnEnd) {
      OnEnd({ Item: Item, close: close ?? false, });
      return;
    }
    this.Post(`${Item.id ? '/update' : '/add'}`, crudSettings, Item).subscribe(data => {
      this.Saved(Item.id ? Item : data, close, OnEnd);
    })
  }
  Saved(Item, close?: boolean, OnEnd?: (x) => void) {
    if (OnEnd)
      OnEnd({ Item: Item, close: close ?? false, });
  }

  MultipleCustomButtonClick(Items: any[], button: ActionButton, FromLista: boolean, Settings: OggettoConfig, OnEnd?: (x) => void, OnRefreshRequested?: (x) => void, OnClose?: (x) => void,) {
    if (!(Items?.length > 0) || (button?.preAlert && !confirm(button.preAlert))) return;
    if (button.preMex)
      this.Toastr.warning(button.preMex);
    var Structure = Settings;
    if (button.type == 'edit' && button.campi) {
      this.OpenEditModal({}, Settings, button, FromLista, (data) => Items.forEach(item => {
        this.HandleCustomButton(Structure, item, {}, button, FromLista, false, OnEnd, OnRefreshRequested, OnClose);
      }));
    }
    else
      Items.forEach(item => {
        this.HandleCustomButton(Structure, item, {}, button, FromLista, false, OnEnd, OnRefreshRequested, OnClose);
      });
  }

  //Gestione azioni dinamiche
  CustomButtonClick(Item: Object, button: ActionButton, FromLista: boolean, Settings: OggettoConfig, OnEnd?: (x) => void, OnRefreshRequested?: (x) => void, OnClose?: (x) => void, CustomHandle?: (x) => boolean, component?: any) {
    if ((button?.preAlert && !confirm(button.preAlert))) return;
    if (button.preMex)
      this.Toastr.warning(button.preMex);
    this.HandleCustomButton(Settings, Item, {}, button, FromLista, true, OnEnd, OnRefreshRequested, OnClose, CustomHandle, component);
  }

  OperazioneConclusa(data, button: ActionButton, OnEnd?: (x) => void, OnRefreshRequested?: (x) => void, OnClose?: (x) => void,) {
    if (button.successMex)
      this.Toastr.success(button.successMex);
    if (button.close && OnClose)
      OnClose(data);
    else
      OnRefreshRequested(data);
    if (OnEnd)
      OnEnd(data);
  }

  HandleCustomButton(Settings: OggettoConfig, task: any, TaskEdited, button: ActionButton, PerformGet: boolean, ShowEditModal: boolean = false, OnEnd?: (x) => void, OnRefreshRequested?: (x) => void, OnClose?: (x) => void, CustomHandle?: (x) => boolean, component?: any) {
    var filter = button.sendFullObject ? task : {};
    button.parameter?.forEach(param => {
      var p = param?.parameter?.split('.');
      var parameter = Object.assign({}, task)
      p?.forEach(el => {
        parameter = this.getparam(parameter, el);
      })
      filter[param.nome] = param.value ?? parameter
    })
    if (button?.restRoute?.includes(':id'))
      button.restRoute = button.restRoute.replace(':id', task.id)
    if (button.type == 'GET' && button.restRoute)
      this.api.ClassicGet(button.restRoute).subscribe(data => {
        this.OperazioneConclusa(data, button, OnEnd, OnRefreshRequested, OnClose);
      })
    else if (button.type == 'POST' && button.restRoute)
      this.api.ClassicPost(button.restRoute, filter).subscribe(data => {
        this.OperazioneConclusa(data, button, OnEnd, OnRefreshRequested, OnClose);
      })
    else if (button.type == 'GOTO' && button.restRoute) {
      window.open(button.restRoute, '_blank');
      this.OperazioneConclusa({}, button, OnEnd, OnRefreshRequested, OnClose);
    }
    else if (PerformGet)
      this.GetItem(Settings, { id: task.id }).subscribe(Item => {
        this.ExecuteCustomButton(Item, button, Settings, TaskEdited, PerformGet, ShowEditModal, OnEnd, OnRefreshRequested, OnClose);
      })
    else
      this.ExecuteCustomButton(task, button, Settings, TaskEdited, PerformGet, ShowEditModal, OnEnd, OnRefreshRequested, OnClose, CustomHandle, component);
  }
  ExecuteCustomButton(Item, button: ActionButton, Structure, TaskEdited, FromLista, ShowEditModal = false, OnEnd?: (x) => void, OnRefreshRequested?: (x) => void, OnClose?: (x) => void, CustomHandle?: (x) => boolean, component?: any) {
    if (button?.type == 'addTask' && button?.taskType) {
      this.CreateRelatedTask(this.campoUtility.getDynamicTaskSctructure(button.taskType, button.taskFilter), Item, button.concat, button.copy, (x) => {
        this.OperazioneConclusa(x, button, OnEnd, OnRefreshRequested, OnClose)
      })
    }
    else if (button.type == 'addTaskRicorrente' && button?.taskType) {
      var tasktype = this.campoUtility.getDynamicTaskSctructure(button.taskType, button.taskFilter);
      var newtask = {};
      button.concat?.forEach(concatObj => {
        newtask[concatObj.to] = newtask[concatObj.to] ? newtask[concatObj.to] + this.campoUtility.getObj(Item, concatObj.from) : this.campoUtility.getObj(Item, concatObj.from);
      });
      button.copy?.forEach(concatObj => {
        newtask[concatObj.to] = this.campoUtility.getObj(Item, concatObj.from);
      });
      if (!Item.data_fine_prevista) {
        this.Toastr.error("Indicare la data di fine per utilizzare questa funzionalità", "Errore");
        return;
      }
      if (!tasktype?.campi?.some(x => this.campoUtility.Show(x, newtask, true)))
        this.CreatePeriodicRelatedTask(Item, newtask, tasktype);
      else {
        const editmodal = this.modalService.open(NewsComponent, {
          centered: true,
          backdrop: 'static',
          size: 'xl',
        });
        (<NewsComponent>editmodal.componentInstance).DynamicFields = tasktype;
        (<NewsComponent>editmodal.componentInstance).ExternalAdd = true;
        (<NewsComponent>editmodal.componentInstance).IsModal = true;
        (<NewsComponent>editmodal.componentInstance).Item = newtask;
        (<NewsComponent>editmodal.componentInstance).FromLista = FromLista;
        (<NewsComponent>editmodal.componentInstance).updated.subscribe(data => {
          this.CreatePeriodicRelatedTask(Item, data, tasktype)
        });
      }
    }
    else if (button.type == 'esegui') {
      Item.data_inizio_effettiva = this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm')
      this.Save(Item, Structure);
    }
    else if (button.type == 'edit' && button.campi) {
      if (ShowEditModal) {
        this.OpenEditModal(Item, Structure, button, FromLista, (data) => {
          var structure = Object.assign({}, Structure);
          structure.campi = button.campi;
          structure.sezioni = button.sezioni;
          var tsk = Object.assign(Item, data)
          this.Save(tsk, structure, button.close);
        });
      } else {
        var structure = Object.assign({}, Structure);
        structure.campi = button.campi;
        structure.sezioni = button.sezioni;
        var tsk = Object.assign(Item, TaskEdited)
        this.Save(tsk, structure, button.close);
      }
    }
    else {
      if (CustomHandle?.({ item: Item, button: button, component: component }))
        this.OperazioneConclusa({}, button, OnEnd, OnRefreshRequested, OnClose);
    }
  }

  OpenEditModal(Item, Settings, button: ActionButton, PerformGet: boolean = false, OnEnd?: (x) => void,) {
    var structure = Object.assign({}, Settings);
    structure.campi = button.campi;
    structure.sezioni = button.sezioni;
    if (button.otp && Item?.id) {
      this.api.ClassicPost(`${button.restRoute}/otp`, { id: Item?.id }).subscribe(data => {
        this.Toastr.info(data.message)
      });
    }
    const editmodal = this.modalService.open(DynamicEditComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });
    (<DynamicEditComponent>editmodal.componentInstance).CrudSettings = structure;
    (<DynamicEditComponent>editmodal.componentInstance).ExternalAdd = true;
    (<DynamicEditComponent>editmodal.componentInstance).IsModal = true;
    (<DynamicEditComponent>editmodal.componentInstance).Item = {};
    (<DynamicEditComponent>editmodal.componentInstance).FromLista = PerformGet;
    (<DynamicEditComponent>editmodal.componentInstance).updated.subscribe(data => {
      if (OnEnd)
        OnEnd(data);
    });
  }

  CreateRelatedTask(dynamicField, Item, concat?: { from: string, to: string, value: any }[], copy?: { from: string, to: string, value: any }[], OnRefreshRequested?: (x) => void) {
    var cliente;
    if (Item['clientiObject'])
      cliente = Item['clientiObject'].map(x => x.id);
    var newTask = {
      cliente: Item.cliente,
      clienti: cliente,
      clienteObject: Item.clienteObject,
      valutazione: Item.type == "V" ? Item.id : null,
      padre: ((Item.type == "O" && dynamicField.type == 'G') || Item.type == "E" || (dynamicField.type == "Y")) ? Item.id : null,
      rma: Item.type == "R" ? Item.id : null,
      obiettivo: Item.type == "O" ? Item.id : null,
      scadenza: Item.type == "S" ? Item.id : null,
      nome: Item.type == "E" ? Item.nome : "",
      prodotto: Item.type == "SERIALE" ? Item.id : null,
      prodottoObject: Item.type == "SERIALE" ? Item : null,
      tipo: dynamicField.tipo,
    };
    concat?.forEach(concatObj => {
      var p = concatObj.from?.split('.');
      var parameter = Object.assign({}, Item)
      p?.forEach(el => {
        parameter = this.getparam(parameter, el);
      })// TODO: gestire array meglio!!

      newTask[concatObj.to] = newTask[concatObj.to] ? newTask[concatObj.to] + parameter : parameter;
    });
    copy?.forEach(concatObj => {
      if (concatObj.from)
        newTask[concatObj.to] = Item[concatObj.from];
      else if (concatObj.value)
        newTask[concatObj.to] = concatObj.value;
    });
    const editmodal = this.modalService.open(NewsComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });
    (<NewsComponent>editmodal.componentInstance).DynamicFields = dynamicField;
    (<NewsComponent>editmodal.componentInstance).IsModal = true;
    (<NewsComponent>editmodal.componentInstance).Item = newTask;
    (<NewsComponent>editmodal.componentInstance).updated.subscribe(data => { if (OnRefreshRequested) OnRefreshRequested(data) });
  }
  CreatePeriodicRelatedTask(Item, data: any, tasktype: any, OnEnd?: (x) => void) {
    if (!Item.data_fine_prevista) {
      this.Toastr.error("Indicare la data di fine per utilizzare questa funzionalità", "Errore");
      return;
    }
    var dates = Item?.ripetizione?.nGiorni ? this.generateDateArrayEveryXDays(
      Item.data_inizio_prevista,
      parseInt(Item?.ripetizione?.nGiorni.toString()),
      Item.data_fine_prevista,
    ) : this.generateDateArray(
      Item.data_inizio_prevista ?? Item['data_attivita'],
      Item.data_fine_prevista,
      Item.ripetizione?.repeatEvery,
      Item.ripetizione?.frequency,
      Item.ripetizione?.daysOfWeek,
      Item.ripetizione?.endCondition,
      Item.ripetizione?.endDate,
      Item.ripetizione?.occurrences
    );
    dates.forEach(date => {
      var newtask = Object.assign({}, data);
      newtask.data_inizio_prevista = `${date}T00:00`;
      newtask.data_attivita = `${date}T00:00`;
      newtask.data_fine_prevista = `${date}T23:59`;
      this.api.ClassicPost(`${tasktype.restRoute}/add`, newtask).subscribe(() => {

      });
    })
  }
  generateDateArray(startDate: string, endDate: string, repeatEvery: number, frequency: string, daysOfWeek: any, endCondition: string, endDateCondition: string, occurrences: number): string[] {
    var exError = this.validateRipetizione(startDate, endDate, repeatEvery, frequency, daysOfWeek, endCondition, endDateCondition, occurrences)
    if (exError) {
      this.Toastr.error(exError, 'Errore')
      return [];
    }

    const result: string[] = [];
    let currentDate = new Date(startDate);
    const finalDate = endCondition === 'date' ? new Date(endDateCondition!) : new Date(endDate);
    let count = 0;
    // Helper function to format date as yyyy-MM-dd
    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    while (currentDate <= finalDate && (endCondition !== 'occurrences' || count < occurrences!)) {
      const dayOfWeek = currentDate.getDay();
      const dayMap = {
        0: 'sunday',
        1: 'monday',
        2: 'tuesday',
        3: 'wednesday',
        4: 'thursday',
        5: 'friday',
        6: 'saturday'
      };

      if (frequency !== 'weeks' || daysOfWeek[dayMap[dayOfWeek]]) {
        result.push(formatDate(currentDate));
        count++;
      }

      switch (frequency) {
        case 'days':
          currentDate.setDate(currentDate.getDate() + repeatEvery);
          break;
        case 'weeks':
          currentDate.setDate(currentDate.getDate() + repeatEvery * 7);
          break;
        case 'months':
          currentDate.setMonth(currentDate.getMonth() + repeatEvery);
          break;
        case 'years':
          currentDate.setFullYear(currentDate.getFullYear() + repeatEvery);
          break;
        default:
          throw new Error(`Unsupported frequency: ${frequency}`);
      }
    }

    return result;

  }
  generateDateArrayEveryXDays(startDate: string, intervalDays: number, endDate?: string): string[] {
    const result: string[] = [];
    let currentDate = new Date(startDate);
    var finalDate = new Date(endDate);
    finalDate.setDate(finalDate.getDate() + 1)

    if (isNaN(currentDate.getTime()) || isNaN(finalDate.getTime())) {
      console.error('Date non valide:', startDate, endDate);
      return result;
    }
    if (intervalDays <= 0) {
      console.error('intervalDays deve essere un numero positivo:', intervalDays);
      return result;
    }

    // Helper function to format date as yyyy-MM-dd
    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    // Loop per generare le date
    while (currentDate <= finalDate) {
      result.push(formatDate(currentDate));
      var dummy = new Date(currentDate)
      dummy.setDate(currentDate.getDate() + intervalDays);
      currentDate = dummy;
    }
    console.log('Date da aggiungere', result);
    return result;
  }
  validateRipetizione(
    startDate: string,
    endDate: string,
    repeatEvery: number,
    frequency: string,
    daysOfWeek: any,
    endCondition: string,
    endDateCondition?: string,
    occurrences?: number
  ): string | null {
    const validFrequencies = ['days', 'weeks', 'months', 'years'];
    const validEndConditions = ['never', 'date', 'occurrences'];

    const isValidDate = (dateStr: string): boolean => !isNaN(new Date(dateStr).getTime());

    if (!isValidDate(startDate)) {
      return 'La data di inizio non è valida.';
    }

    if (endCondition === 'date' && (!endDateCondition || !isValidDate(endDateCondition))) {
      return 'La data di fine non è valida.';
    }

    if (endCondition === 'date' && new Date(endDateCondition!) <= new Date(startDate)) {
      return 'La data di fine deve essere successiva alla data di inizio.';
    }

    if (repeatEvery <= 0) {
      return 'L\'intervallo di ripetizione deve essere un numero positivo maggiore di zero.';
    }

    if (!validFrequencies.includes(frequency)) {
      return 'La frequenza non è valida.';
    }

    if (!validEndConditions.includes(endCondition)) {
      return 'La condizione di fine non è valida.';
    }

    if (endCondition === 'occurrences' && (!occurrences || occurrences <= 0)) {
      return 'Il numero di occorrenze deve essere un numero positivo maggiore di zero.';
    }

    if (frequency === 'weeks' && !Object.values(daysOfWeek).some(day => day)) {
      return 'Deve essere selezionato almeno un giorno della settimana per la frequenza settimanale.';
    }

    return null;
  }


}

export class OggettoConfig {
  listTitle?: string;
  HasAddList?: CheckObject;
  AddDefaultObject?: Object;
  OnSaveMap?: (x) => void;
  EditView?: 'Modal' | 'Page';
  Capacita?: string[];
  Id: string;
  RestRoute?: string;
  ListResponseHasData?: boolean;
  GetResponseHasData?: boolean;
  ListDefaultFilter?: object;
  MenuItems?: any[];
  campi?: any[];
  sezioni?: any[];
  Azioni?: any[];
  Headers?: any[];
  Filters?: any[];
  HasGet?: boolean;

}

export class ActionButton {
  type?: 'GET' | 'POST' | 'GOTO' | 'addTask' | 'addTaskRicorrente' | 'esegui' | 'edit';
  concat?: { from: string, to: string, value: any }[];
  copy?: { from: string, to: string, value: any }[];
  restRoute?: string;
  preMex?: string;
  successMex?: string;
  parameter?: any[];
  preAlert?: string;
  close?: boolean;
  taskType?: string;
  taskFilter?: string;
  campi?: any[];
  otp?: boolean;
  sezioni?: any[];
  HideButtons?: boolean;
  sendFullObject?: boolean;
}