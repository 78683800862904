import { Component, EventEmitter, Input, Output } from "@angular/core";
import {  CampoUtility } from "../models/campo-aggiuntivo";
import { Permission } from "src/app/Login/_guards/Permission";
import {Color, hsl} from "d3";


@Component({
    selector: 'app-dynamic-list-row',
    templateUrl: './dynamic-list-row.component.html'
})
export class DynamicListRowComponent {
    @Input() item: any ;// item della riga
    @Input() campo: any;
    constructor(public campoUtility: CampoUtility, public permission: Permission) {}
    LightenColor(color: Color, percent = 50) {
        var num = parseInt(color.toString().replace("#", ""), 16);
        if (num < 16750000)
          return hsl(0, 0, 100);
        else
          return hsl(0, 0, 0);
      };
}