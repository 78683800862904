import {getModule} from "./Utility/ModuleUtility";


import {Environment as Env} from "./BaseEnvironments/Klimaitalia";

export var Environment = () => {
  var env= Env();
  return env

}

export var environment = Environment();
